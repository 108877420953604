import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, Card, Table, Badge, Col, Button } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import {
  blockUser,
  getAllUsers,
  getTransactions,
  verifyUser,
} from "../../services/User/UserService";
import dummy from "../../images/Profile_avatar_placeholder_large.png";
import { notifyError, notifyTopRight } from "../common/Toaster";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useHistory } from "react-router-dom";
export default function TransactionList(props) {
  // console.log(props, "propsssss");
  const history = useHistory();
  const newpage = props.location.state?.page;
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(newpage ? newpage : 0);
  const [search, setSearch] = useState("");
  const [listLength, setListlenght] = useState("");
  const [limit, setLimit] = useState(10);
  const [userType, setUserType] = useState("all");
  const imgUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const [country, setCountry] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [countryName, setCountryName] = useState("");

  //////select country/////
  const changeHandler = (value) => {
    setCountry(value);
    setCountryName(value.value);
  };

  ///////////////// function to get all users /////////////////////////////////
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getTransactions(currentPage, limit);
      console.log(response.data.data);
      setUsers(response.data.data.transaction);
      setLoader(false);
      const total = response.data.data.count;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.data.count);
      if(response.data.data.transaction<limit){
        setCurrentPage(0);
      }else{
        setCurrentPage(currentPage);

      }
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
      if (error?.response?.data?.statusCode === 401) {
        localStorage.removeItem("pukkatokenDetails");
        props.history.push("/login");
      }
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage,limit]);

  return (
    <div>
      <PageTitle activeMenu="Transaction List" motherMenu="Transactions" />

      <Card>
        <Card.Body>
          <Table responsive>
            <thead className="text-black">
              <tr>
                <th>
                  <strong>User Name</strong>
                </th>

                <th>
                  <strong>Amount</strong>
                </th>
                <th>
                  <strong>Coins</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((item, i) => (
                <tr key={i}>
                  <td
                    onClick={() =>
                      props.history.push({
                        pathname: "/user-details",
                        state: { id: item?.userId?._id, page: currentPage },
                      })
                    }
                    className="pointer"
                  >
                    {item.userId?.displayName ? item.userId?.displayName : "--"}
                  </td>
                  <td>{item.amount ? "$" + item.amount : "--"}</td>
                  <td>{item.coins}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {users?.length === 0 && !loader && (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          )}

          {users?.length !== 0 && (
            <div className="card-footer clearfix">
              <div className="d-block d-md-flex justify-content-between align-items-center">
                <div className="dataTables_info mb-2 mb-md-0">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLength > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLength}{" "}
                  of {listLength} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
              <div  className="mt-2 mt-md-0">
                <label className="mr-2">Result Per page : </label>
                <select className="" onChange={(e) => setLimit(e.target.value)}>
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>50</option>
                  <option>100</option>
                </select>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>

      {loader && <Spinner />}
    </div>
  );
}
