import instance from "../Instance";
import {
  APPROVE_USER_DETAILS,
  BLOCK_USERS,
  DELETE_STORY,
  DELETE_USER,
  GET_ALL_STORY,
  GET_GIFTS,
  GET_GROUPS,
  GET_ONE_USERS,
  GET_STORY,
  GET_STREAMINGS,
  GET_TRANSACTION,
  GET_USERS,
  PAYOUTS,
  PAYOUTS_ACTION,
  REMOVE_IMAGES,
  VERIFY_USERS,
} from "./UserApiEndPoints";

////    get users list //////////
export function getAllUsers(
  currentPage,
  limit,
  search,
  country,
  userType,
  exportData,
  deviceType
) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(
    GET_USERS +
      `?skip=${currentPage}&limit=${limit}&search=${search}&country=${country}&userType=${userType}&exportData=${exportData}&deviceType=${deviceType}`,
    {
      headers: myHeaders,
    }
  );
}

//////// get single user details ///////
export function getOneUsers(id) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_ONE_USERS + `?userId=${id}`, {
    headers: myHeaders,
  });
}

///////get gifts list ////////
export function getGifts(id, type, currentPage, limit) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(
    GET_GIFTS + `?userId=${id}&type=${type}&page=${currentPage}&limit=${limit}`,
    {
      headers: myHeaders,
    }
  );
}

//////// block/ unblock user /////
export function blockUser(id, isBlocked) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const postData = { userId: id, isBlocked };
  return instance.put(BLOCK_USERS, postData, {
    headers: myHeaders,
  });
}
//////// block/ unblock user /////
export function deleteUser(id) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.delete(DELETE_USER + `?userId=${id}`, {
    headers: myHeaders,
  });
}
//////// verify user /////
export function verifyUser(id, verifiedUser) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const postData = { userId: id, verifiedUser };
  return instance.put(VERIFY_USERS, postData, {
    headers: myHeaders,
  });
}

////    get payout list //////////
export function getPayoutsList(currentPage, limit, isNew) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(
    PAYOUTS + `?page=${currentPage}&limit=${limit}&isNew=${isNew}`,
    {
      headers: myHeaders,
    }
  );
}

export function approvePayout(payoutId, isApproved) {
  const postData = {
    payoutId,
    action: isApproved,
  };
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.put(PAYOUTS_ACTION, postData, {
    headers: myHeaders,
  });
}

export function updateImages(userId, images, selfie, profileVideo) {
  const postData = {
    userId,
    images,
    selfie,
    videoUrl: profileVideo,
  };
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.put(REMOVE_IMAGES, postData, {
    headers: myHeaders,
  });
}

///////get story list ////////
export function getStories(id) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_STORY + `?userId=${id}`, {
    headers: myHeaders,
  });
}

///////delete story ////////
export function deleteStory(id) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.delete(DELETE_STORY + `?storyId=${id}`, {
    headers: myHeaders,
  });
}

///////get transaction list ////////
export function getTransactions(currentPage, limit) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_TRANSACTION + `?page=${currentPage}&limit=${limit}`, {
    headers: myHeaders,
  });
}
///////get story list ////////
export function getAllStories(currentPage, limit) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_ALL_STORY + `?page=${currentPage}&limit=${limit}`, {
    headers: myHeaders,
  });
}
///////get transaction list ////////
export function getStremings(currentPage, limit) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_STREAMINGS + `?page=${currentPage}&limit=${limit}`, {
    headers: myHeaders,
  });
}

///////get group list ////////

export function getGroups(currentPage, limit) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_GROUPS + `?page=${currentPage}&limit=${limit}`, {
    headers: myHeaders,
  });
}
