import React, { useEffect, useState } from "react";
import { MultiSelect } from "../components/MultiSelect";
import PageTitle from "../layouts/PageTitle";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  getUsersAll,
  postNotification,
} from "../../services/Notification/NotificationService";
import { notifyError, notifyTopRight } from "../common/Toaster";
import Spinner from "../common/Spinner";
export default function Notification() {
  let errorsObj = { title: "", body: "", type: "", deviceType:"", selected: [] };
  const history = useHistory();
  const [errors, setErrors] = useState(errorsObj);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [selected, setSelected] = useState([]);
  const [loader, setLoader] = useState(false);
  const [userList, setUserList] = useState([]);
  const [sendToAll, setSendToAll] = useState(false);
  const [deviceType, setDeviceType] = useState("");

  ///// function to create option list ///
  const options = [
    ...userList?.map((item, i) => {
      return {
        value: item?._id,
        label: item?.displayName ? item?.displayName : "--",
      };
    }),
  ];

  const totalIds = [...selected];

  /////selected ids in array///
  const ids = totalIds?.map((item, i) => {
    return item?.value;
  });

  ///// function to updated sendToall  ////
  function handleSendToAll() {
    if (ids.length === userList.length) {
      console.log(ids.length, userList.length, "if");
      setSendToAll(true);
    } else {
      console.log(ids.length, userList.length, "else");
      setSendToAll(false);
    }
  }

  ///////////////// function to get all users /////////////////////////////////
  const getUserList = async () => {
    setLoader(true);
    try {
      const response = await getUsersAll();
      setUserList(response.data.data);
      setLoader(false);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
      if (error?.response?.data?.statusCode === 401) {
        localStorage.clear("pukkatokenDetails");
        history.push("/login");
      }
    }
  };

  //// function to post data ///
  const onSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (title.trim() === "") {
      errorObj.title = "Title is required";
      error = true;
    }
    if (body.trim() === "") {
      errorObj.body = "Body is required";
      error = true;
    }
    if (deviceType.trim() === "") {
      errorObj.deviceType = "This filed is required";
      error = true;
    }
    // if (selected === []) {
    //   errorObj.selected = "Please select an user";
    //   error = true;
    // }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    try {
      const response = await postNotification(
        ids,
        sendToAll,
        title,
        body,
        deviceType
      );
      notifyTopRight("Sent Successfully.");
      setTitle("");
      setBody("");
      setSelected([]);
      setLoader(false);
    } catch (error) {
      setLoader(false);

      if (error.response.data.statusCode === 401) {
        localStorage.clear("pukkatokenDetails");
        history.push("/login");
      } else if (error.response.data.statusCode === 500) {
        notifyError("Something went wrong please try again!");
        setLoader(false);
      } else notifyError(error?.response?.data?.data);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);
  useEffect(() => {
    handleSendToAll();
  }, [ids]);
  return (
    <div>
      <PageTitle activeMenu="Send Notification" motherMenu="Notification" />

      <div className="col-lg-8 col-md-7 ">
        <div className="authincation-content text-black p-5">
          <div className="mb-4">
            <h3 className="mb-1 font-w600 text-black ">Add Details</h3>
          </div>

          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Title</strong>
              </label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter title"
              />
              {errors.title && (
                <div className="text-danger fs-12">{errors.title}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Body</strong>
              </label>
              <input
                type="text"
                className="form-control"
                value={body}
                onChange={(e) => setBody(e.target.value)}
                placeholder="Write something here.."
              />
              {errors.body && (
                <div className="text-danger fs-12">{errors.body}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Device Type</strong>
              </label>

              <select
                className="form-control"
                value={deviceType}
                onChange={(e) => setDeviceType(e.target.value)}
              >
                <option value={""} hidden disabled>
                  select type
                </option>
                <option value={"ALL"}>All</option>
                <option value={"IOS"}>IOS</option>
                <option value={"ANDROID"}>ANDROID</option>
              </select>
              {errors.deviceType && (
                <div className="text-danger fs-12">{errors.deviceType}</div>
              )}
            </div>

            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Send To</strong>
              </label>

              <MultiSelect
                className="form-control"
                options={options}
                value={selected}
                onChange={setSelected}
                required
              />
              {errors.selected && (
                <div className="text-danger fs-12">{errors.selected}</div>
              )}
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-primary btn-block">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
      {loader && <Spinner />}
    </div>
  );
}
