import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { Badge, Card, Dropdown, Nav, Tab, Table } from "react-bootstrap";
import Pagination from "../common/Pagination";
import Spinner from "../common/Spinner";
import { notifyError, notifyTopRight } from "../common/Toaster";

import {
  deleteReport,
  getUserReports,
} from "../../services/Reports/ReportsService";

export default function Reports(props) {
  const [loader, setLoader] = useState(false);
  const [reports, setReports] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListlenght] = useState("");
  const [limit, setLimit] = useState(10);


  ///////////////// function to get reported users list /////////////////////////////////
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getUserReports(currentPage,limit);
      console.log(response.data.data);
      setReports(response.data.data.reports);
      setLoader(false);
      const total = response.data.data.count;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.data.count);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
      if (error?.response?.data?.statusCode === 401) {
        localStorage.clear("pukkatokenDetails");
        props.history.push("/login");
      }
    }
  };
  ///////////////// function to delete report /////////////////////////////////
  const onDelete = async (id) => {
    setLoader(true);
    try {
      const response = await deleteReport(id);
      notifyTopRight("Deleted Successfully!");
      getTableData();
      setLoader(false);
      console.log(response);
    } catch (error) {
      console.log(error.response, "helooooooooo");
      setLoader(false);
      notifyError(error.response?.data?.message);
    }
  };
  useEffect(() => {
    getTableData();
  }, [currentPage,limit]);

  return (
    <div>
      <PageTitle activeMenu="Reports" motherMenu="Reports" />

      <Card>
        <Card.Body>
          <Table responsive>
            <thead className="text-black">
              <tr>
                <th>
                  <strong>title</strong>
                </th>

                <th>
                  <strong>description</strong>
                </th>

                <th>
                  <strong>reported user</strong>
                </th>
                <th>
                  <strong>reported by</strong>
                </th>
                <th className="text-center">
                  <strong>Action</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {reports?.map((item, i) => (
                <tr key={i}>
                  <td>{item?.title}</td>

                  <td>
                    {item?.description.length > 100
                      ? item?.description.slice(0, 100) + "..."
                      : item?.description}
                  </td>
                  <td
                    onClick={() =>
                      props.history.push({
                        pathname: "/user-details",
                        state: {id:item?.reportUserId?._id},
                      })
                    }
                    className="pointer"
                  >
                    {item?.reportUserId?.displayName}
                  </td>
                  <td
                    onClick={() =>
                      props.history.push({
                        pathname: "/user-details",
                        state: {id:item?.userId?._id},
                      })
                    }
                    className="pointer"
                  >
                    {item?.userId?.displayName}
                  </td>
                  <td className="text-center">
                    <button type="button" className="btn btn-danger light p-2" onClick={() => onDelete(item?._id)}>
                      <i className="flaticon-381-trash-1"></i>
                    </button>
                  
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {reports?.length === 0 && !loader && (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          )}
          {reports?.length !== 0 && (
            <div className="card-footer clearfix">
              <div className="d-block d-md-flex justify-content-between align-items-center">
                <div className="dataTables_info mb-2 mb-md-0">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLength > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLength}{" "}
                  of {listLength} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
              <div  className="mt-2 mt-md-0">
                <label className="mr-2">Result Per page : </label>
                <select className="" onChange={(e)=>setLimit(e.target.value)}>
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>50</option>
                  <option>100</option>
                </select>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>

      {loader && <Spinner />}
    </div>
  );
}
