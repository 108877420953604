import React, { useEffect, useRef, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import dummy from "../../images/dummyImage.png";
import { connect } from "react-redux";
import play from "../../images/play (9) 1.svg";
import blueTick from "../../images/Group 62.png";
import {
  deleteStory,
  deleteUser,
  getGifts,
  getOneUsers,
  getStories,
  updateImages,
  verifyUser,
} from "../../services/User/UserService";
import Spinner from "../common/Spinner";
import moment from "moment/moment";
import { notifyError, notifyTopRight } from "../common/Toaster";
import Pagination from "../common/Pagination";
import crossIcon from "../../images/svg/deleteupload.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import FullImage from "../modal/FullImage";
import SendNotification from "../modal/SendNotification";
import playBtn from "../../images/play (9) 1.svg";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";

// Register the English language data
countries.registerLocale(enLocale);
function UserDetails(props) {
  const imgUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  const baseVideoUrl =
    "https://balmam.s3.ap-south-1.amazonaws.com/user_videos/";
  const storyImgUrl =
    "https://balmam.s3.ap-south-1.amazonaws.com/story_images/";
  const storyVideoUrl =
    "https://balmam.s3.ap-south-1.amazonaws.com/story_videos/";

  const selfieBaseUrl =
    "https://balmam.s3.ap-south-1.amazonaws.com/verification_photo/";
  const id = props.location.state.id;

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({});
  const [gifts, setGifts] = useState([]);
  const [giftsent, setGiftSent] = useState([]);
  const [images, setImages] = useState([]);
  const [updatedImages, setUpdatedImages] = useState([]);
  const [myFilters, setMyFilters] = useState([]);
  const [seekingFilters, setSeekingFilters] = useState([]);
  const [storyData, setStoryData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListlenght] = useState("");
  const [pageCount1, setPageCount1] = useState(0);
  const [currentPage1, setCurrentPage1] = useState(0);
  const [listLength1, setListlenght1] = useState("");
  const [pageCount2, setPageCount2] = useState(0);
  const [currentPage2, setCurrentPage2] = useState(0);
  const [listLength2, setListlenght2] = useState("");
  const [show, setShow] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [selfie, setSelfie] = useState(data?.selfie);
  const [profileVideo, setProfileVideo] = useState(data?.videoUrl);
  const limit = 10;
  const [view, setView] = useState(false);
  const [sendNoti, setSendNoti] = useState(false);
  const [imageForView, setImageForView] = useState("");
  const [playVdo, setPlayVdo] = useState(false);
  /////fetch user details/////
  const handleFetch = async () => {
    setLoader(true);
    try {
      const result = await getOneUsers(id);
      console.log(result.data.data, "profile data");
      setData(result.data.data.user);
      setImages(result.data.data.user.images);
      setMyFilters(result.data.data.user.myFilters);
      setSeekingFilters(result.data.data.user.seekingFilters);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      if (error.response.data.statusCode === 401) {
        localStorage.clear("pukkatokenDetails");
        props.history.push("/login");
      } else if (error.response.data.statusCode === 500) {
        notifyError("Something went wrong please try again!");
        setLoader(false);
      } else notifyError(error?.response?.data?.data);
    }
  };

  /////// function to fetch recieved gifts list //////
  const handleFetchRecievedGifts = async (type) => {
    setLoader(true);
    try {
      const result = await getGifts(id, type, currentPage, limit);
      console.log(result.data.data, "recieved data");
      setGifts(result.data.data.user.recievedGifts);
      setLoader(false);
      const total = result.data.data.count;
      setPageCount(Math.ceil(total / limit));
      setListlenght(result.data.data.count);
    } catch (error) {
      setLoader(false);
      if (error?.response?.data?.statusCode === 401) {
        localStorage.clear("pukkatokenDetails");
        props.history.push("/login");
      } else if (error?.response?.data?.statusCode === 500) {
        notifyError("Something went wrong please try again!");
        setLoader(false);
      } else notifyError(error?.response?.data?.data);
    }
  };

  /////// function to fetch sent gifts list //////

  const handleFetchSentGifts = async (type) => {
    setLoader(true);
    try {
      const result = await getGifts(id, type, currentPage1, limit);
      console.log(result.data.data, "sent data");
      setGiftSent(result.data.data.user.sendedGifts);
      setLoader(false);
      const total = result.data.data.count;
      setPageCount1(Math.ceil(total / limit));
      setListlenght1(result.data.data.count);
    } catch (error) {
      setLoader(false);
      if (error?.response?.data?.statusCode === 401) {
        localStorage.clear("pukkatokenDetails");
        props.history.push("/login");
      } else if (error?.response?.data?.statusCode === 500) {
        notifyError("Something went wrong please try again!");
        setLoader(false);
      } else notifyError(error?.response?.data?.data);
    }
  };

  /////// function to fetch sent gifts list //////

  const handleFetchStories = async () => {
    setLoader(true);
    try {
      const result = await getStories(id);
      console.log(result.data.data, "story  data");
      setStoryData(result.data.data);
      setLoader(false);
      const total = result.data.data.count;
      setPageCount2(Math.ceil(total / limit));
      setListlenght2(result.data.data.count);
    } catch (error) {
      setLoader(false);
    }
  };

  /////image with cross icon
  const ImageWithCrossIcon = ({ image, index, onRemove }) => (
    <div className="position-relative">
      <img
        src={crossIcon}
        alt="Cross Icon"
        className={`cross-icon pointer ${image ? "" : "d-none"}`}
        onClick={() => onRemove(index)}
      />
      <img
        src={image ? imgUrl + image : dummy}
        width={index === 0 ? 177 : 80}
        height={index === 0 ? 177 : 80}
        className="text-center pointer"
        style={{ transition: "width 0.5s, height 0.5s" }}
        onClick={() => {
          if (image) {
            setImageForView(imgUrl + image);
            setView(true);
          }
        }}
      />
    </div>
  );

  ///function to handle remove image by index
  const handleRemoveImage = (indexToRemove) => {
    setImages((prevImages) =>
      prevImages
        .filter((_, index) => index !== indexToRemove)
        .map((image, index) =>
          index >= indexToRemove ? images[index + 1] : image
        )
    );
    setUpdatedImages(images);
  };

  const handleUpdateImages = async () => {
    console.log("hlojdhf");
    setLoader(true);
    try {
      const result = await updateImages(id, images, selfie, profileVideo);
      console.log(result, "updated image api response");
      setShow(false);
      // notifyTopRight(result?.data?.message);
      handleFetch();
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      setShow(false);
      setLoader(false);
    }
  };
  const onRemoveSelfie = () => {
    setShow(true);
    setSelfie("");
  };
  const onRemoveVideo = () => {
    setShow(true);
    setProfileVideo("");
  };

  const handleDeleteStory = async (storyId) => {
    setLoader(true);
    try {
      const result = await deleteStory(storyId);
      console.log(result, "updated api response");
      notifyTopRight(result?.data?.message);
      handleFetchStories();
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      setLoader(false);
    }
  };
  const StoryWithCrossIcon = ({ media, index, onRemove, thumbnail }) => (
    <div className="position-relative">
      {media.type === "image" ? (
        <img
          src={media.url}
          width={"100%"}
          height={200}
          className="mr-2"
          alt={`Story ${index}`}
        />
      ) : // <video
      //   key={index}
      //   width={"100%"}
      //   height="200"
      //   poster={thumbnail}
      //   muted
      //   controls
      //   className="mr-2"
      // >
      //   <source src={media.url} type="video/mp4" />
      // </video>
      playVdo === index ? (
        <video width={"100%"} height="200" controls autoPlay={playVdo && true}>
          <source src={media.url} type="video/mp4" />
        </video>
      ) : (
        <div className="">
          <img width={"100%"} height={200} src={thumbnail} />
          <img
            src={playBtn}
            className="vdo-play-btn2"
            onClick={() => setPlayVdo(index)}
          />
        </div>
      )}

      <img
        src={crossIcon}
        alt="Cross Icon"
        className={`cross-icon pointer`}
        onClick={() => onRemove(index)}
      />
    </div>
  );
  ///////////////// function to verify user /////////////////////////////////
  const onVerify = async (verifiedUser) => {
    setLoader(true);
    try {
      const response = await verifyUser(id, verifiedUser);
      {
        verifiedUser
          ? notifyTopRight("Verified Successfully.")
          : notifyTopRight("Rejected Successfully.");
      }

      handleFetch();
      setLoader(false);
      console.log(response);
    } catch (error) {
      console.log(error, "helooooooooo");
      setLoader(false);
      notifyError(error.response?.data?.message);
      if (error.response?.data?.statusCode === 401) {
        localStorage.removeItem("pukkatokenDetails");
        props.history.push("/login");
      }
    }
  };

  ///////////////// function to delete user /////////////////////////////////

  const onDeleteUser = () => {
    setShowNew(true);
  };
  const handleDeleteUser = async (userId) => {
    setLoader(true);
    try {
      const result = await deleteUser(userId);
      console.log(result, "updated api response");
      notifyTopRight(result?.data?.message);
      props.history.push("/user-management");
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (props.location.state.id) {
      handleFetch();
    }
  }, []);

  useEffect(() => {
    handleFetchRecievedGifts("received");
    handleFetchSentGifts("sended");
    handleFetchStories();
  }, [currentPage1, currentPage, currentPage2]);

  const AgeDisplay = ({ dob }) => {
    const birthDate = moment(dob);
    const ageInYears = moment().diff(birthDate, "years");
    const ageInMonths = moment().diff(birthDate, "months");

    const displayAge =
      ageInYears >= 1 ? `${ageInYears} years` : `${ageInMonths} months`;

    return (
      <p>
        Age - <b>{displayAge}</b>
      </p>
    );
  };
  return (
    <div>
      {" "}
      <div className="page-titles px-3 d-block d-md-flex justify-content-between align-items-start">
        <div>
          <h4>User Details</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link
                to={{
                  pathname: "/user-management",
                  state: { page: props?.location.state?.page },
                }}
              >
                User Management
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <Link>User Details</Link>
            </li>
          </ol>
        </div>

        <div>
          <button
            className="btn btn-primary py-2 mt-2 mt-md-0"
            onClick={() => setSendNoti(true)}
          >
            Send Notification
          </button>
        </div>
      </div>
      <Card>
        <Card.Header className="d-block align-items-start">
          <div
            className="d-block d-sm-flex justify-content-start"
            style={{ gap: "1rem" }}
          >
            <div>
              <div className="d-flex justify-content-start gap-1">
                {[0].map((index) => (
                  <ImageWithCrossIcon
                    key={index}
                    image={images[index]}
                    index={index}
                    onRemove={handleRemoveImage}
                  />
                ))}

                <div className="d-flex flex-column gap-1">
                  {[1, 2].map((index) => (
                    <ImageWithCrossIcon
                      key={index}
                      image={images[index]}
                      index={index}
                      onRemove={handleRemoveImage}
                    />
                  ))}
                </div>
              </div>
              <div className="d-flex justify-content-start pt-3 mb-3 gap-1">
                {[3, 4, 5].map((index) => (
                  <ImageWithCrossIcon
                    key={index}
                    image={images[index]}
                    index={index}
                    onRemove={handleRemoveImage}
                  />
                ))}
              </div>
            </div>
            <div className="text-left flex-grow-1">
              <div className="d-flex justify-content-between">
                <h3 className="text-color">
                  {data?.displayName}
                  {data?.verifiedUser ? (
                    <img src={blueTick} className="ml-2" />
                  ) : (
                    ""
                  )}
                </h3>
                <div>
                  {updatedImages?.length > 0 && (
                    <button
                      type="button"
                      className="btn btn-primary py-2"
                      onClick={handleUpdateImages}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>

              <div className="d-block d-md-flex" style={{ gap: "1rem" }}>
                <div>
                  <p>
                    First Name - <b>{data?.name}</b>
                  </p>
                  <p>
                    Last Name - <b>{data?.lastName}</b>
                  </p>
                  <AgeDisplay dob={data.dob} />
                  <p>
                    Country -{" "}
                    <b>{countries.getName(data?.address?.country, "en")}</b>
                  </p>
                  {/* <p>Age - {moment().diff(data.dob, "years")}</p> */}
                </div>
                <div className="">
                  <p>
                    Email - <b>{data?.email}</b>
                  </p>
                  {/* <p>Height - {data?.myHeight}Cm</p>
                  <p>Weight - {data?.myWeight}Kg</p> */}
                  <p>
                    Phone Number -{" "}
                    <b>
                      {data.countryCode} {data.phoneNumber}
                    </b>
                  </p>
                  <p>
                    DOB - <b>{moment(data.dob).format("MM/DD/YYYY")}</b>
                  </p>
                  <p>
                    Device Type - <b>{data?.deviceType}</b>
                  </p>
                </div>
              </div>

              <h5 className="text-black  pt-2 pb-1">Bio</h5>
              <p>{data?.aboutMe}</p>
            </div>
          </div>
          {/* <div>
            {updatedImages?.length > 0 && (
              <button
                type="button"
                className="btn btn-primary py-2"
                onClick={handleUpdateImages}
              >
                Save
              </button>
            )}
          </div> */}
        </Card.Header>
      </Card>
      <div className="d-block d-md-flex" style={{ gap: "1rem" }}>
        <Card className="w-100">
          <Card.Header>
            <Card.Title className="text-black">
              <b>Verification Image</b>
            </Card.Title>
            {data?.selfie &&
              (data?.verifiedUser ? (
                <button
                  type="button"
                  className="btn btn-secondary py-2"
                  onClick={() => onVerify(false)}
                >
                  Reject
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary py-2"
                  onClick={() => onVerify(true)}
                >
                  Verify
                </button>
              ))}
          </Card.Header>
          <Card.Body>
            <div className="d-flex justify-content-center align-items-center">
              <div className="position-relative">
                <img
                  src={crossIcon}
                  alt="Cross Icon"
                  className={`cross-icon pointer ${
                    data?.selfie ? "" : "d-none"
                  }`}
                  onClick={() => onRemoveSelfie()}
                />
                <img
                  src={data?.selfie ? selfieBaseUrl + data?.selfie : dummy}
                  alt="image"
                  width={200}
                  className="pointer"
                  onClick={() => {
                    if (data?.selfie) {
                      setImageForView(selfieBaseUrl + data?.selfie);
                      setView(true);
                    }
                  }}
                />
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="w-100">
          <Card.Header>
            <Card.Title className="text-black">
              <b>Profile Video</b>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="d-flex justify-content-center align-items-center">
              <div className="position-relative">
                <img
                  src={crossIcon}
                  alt="Cross Icon"
                  className={`cross-icon pointer ${
                    data?.videoUrl ? "" : "d-none"
                  }`}
                  onClick={() => onRemoveVideo()}
                />

                {data?.videoUrl ? (
                  <video width="300" height={"250"} autoPlay muted controls>
                    <source
                      src={baseVideoUrl + data?.videoUrl}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <>
                    <img src={play} alt="image" />
                    <p className="mb-0">No video uploaded</p>
                  </>
                )}
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <Card>
        <Card.Header>
          <Card.Title>
            <b>Stories</b>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="grid-stories">
            {storyData?.map((item, i) => (
              <StoryWithCrossIcon
                key={i}
                media={
                  item?.videoUrl
                    ? { type: "video", url: storyVideoUrl + item?.videoUrl }
                    : { type: "image", url: storyImgUrl + item?.image }
                }
                index={item?._id}
                onRemove={handleDeleteStory}
                thumbnail={storyImgUrl + item?.thumbnail}
              />
            ))}
          </div>
          {storyData.length === 0 && (
            <div className="text-center ">No Story!</div>
          )}
        </Card.Body>
      </Card>
      <div className="row">
        <div className="col-xl-6 col-xxl-6 col-lg-6 col-md-6 col-sm-12">
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="text-black">
                <h4 className="text-center pb-2 border-bottom">My Filters</h4>
                {myFilters?.map((item, i) => (
                  <div key={i}>
                    <h4 className="text-center py-3">{item.categoryname}</h4>
                    {item?.filters?.map((filter, index) => (
                      <div key={index} className="">
                        <h5 className=" pb-3 text-primary">
                          {filter?.subcategory}
                        </h5>

                        {filter?.filters?.map((final, inx) => (
                          <div
                            key={inx}
                            className="d-flex justify-content-between align-items-center  mb-3 pb-1 border-bottom"
                          >
                            <p className="fs-14 fw-bold text-black">
                              {final?.filterName}
                            </p>
                            <p className="mb-0 w-50 text-right">
                              {final?.filterValue?.join(", ")}
                            </p>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}
                {myFilters.length === 0 && (
                  <div className="text-center pt-3">No filters!</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-xxl-6 col-lg-6 col-md-6 col-sm-12">
          <div className="widget-stat card">
            <div className="card-body p-4">
              <div className="text-black">
                <h3 className="text-center pb-2 border-bottom">
                  Seeking Filters
                </h3>
                {seekingFilters?.map((item, i) => (
                  <div key={i}>
                    <h4 className="text-center py-3">{item.categoryname}</h4>
                    {item?.filters?.map((filter, index) => (
                      <div key={index} className="">
                        <h5 className=" pb-3 text-primary">
                          {filter?.subcategory}
                        </h5>

                        {filter?.filters?.map((final, inx) => (
                          <div className="d-flex justify-content-between align-items-center  mb-3 pb-1 border-bottom">
                            <p className="fs-14 fw-bold text-black">
                              {final?.filterName}
                            </p>
                            <p className="mb-0 w-50 text-right">
                              {final?.filterValue?.join(", ")}
                            </p>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}
                {seekingFilters.length === 0 && (
                  <div className="text-center pt-3">No filters!</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 col-xxl-6 col-lg-6 col-md-6 col-sm-12">
          <Card>
            <Card.Header className="d-block">
              <div className="">
                <h4 className="text-black">Recieved Gifts</h4>
              </div>
            </Card.Header>
            <Card.Body>
              {gifts?.length === 0 && !loader ? (
                <div className="justify-content-center d-flex my-5 ">
                  Sorry, No Data!
                </div>
              ) : (
                <Table responsive>
                  <thead className="text-black">
                    <tr>
                      <th>
                        <strong>name</strong>
                      </th>
                      <th>
                        <strong>gems Amount</strong>
                      </th>
                      <th>
                        <strong>Coins Amount</strong>
                      </th>
                      <th>
                        <strong>Recieved From</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {gifts?.map((item, i) => (
                      <tr key={i}>
                        <td>
                          {item?.giftId?.name ? item?.giftId?.name : "--"}
                        </td>
                        <td>
                          {item?.giftId?.gemsAmount
                            ? item?.giftId?.gemsAmount
                            : "0"}
                        </td>
                        <td>
                          {item?.giftId?.coinsAmount
                            ? item?.giftId?.coinsAmount
                            : "0"}
                        </td>
                        <td>
                          {item?.userId?.displayName
                            ? item?.userId?.displayName
                            : "--"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              {gifts?.length !== 0 && (
                <div className="card-footer clearfix">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Showing {currentPage * limit + 1} to{" "}
                      {listLength > (currentPage + 1) * limit
                        ? (currentPage + 1) * limit
                        : listLength}{" "}
                      of {listLength} entries
                    </div>
                    <Pagination
                      pageCount={pageCount}
                      pageValue={currentPage}
                      setPage={setCurrentPage}
                    />
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
        <div className="col-xl-6 col-xxl-6 col-lg-6 col-md-6 col-sm-12">
          <Card>
            <Card.Header className="d-block">
              <div className="">
                <h4 className="text-black">Sent Gifts</h4>
              </div>
            </Card.Header>
            <Card.Body>
              {giftsent?.length === 0 && !loader ? (
                <div className="justify-content-center d-flex my-5 ">
                  Sorry, No Data!
                </div>
              ) : (
                <Table responsive>
                  <thead className="text-black">
                    <tr>
                      <th>
                        <strong>name</strong>
                      </th>
                      <th>
                        <strong>gems Amount</strong>
                      </th>
                      <th>
                        <strong>Coins Amount</strong>
                      </th>
                      <th>
                        <strong>Sended To</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {giftsent?.map((item, i) => (
                      <tr key={i}>
                        <td>
                          {item?.giftId?.name ? item?.giftId?.name : "--"}
                        </td>
                        <td>
                          {item?.giftId?.gemsAmount
                            ? item?.giftId?.gemsAmount
                            : "0"}
                        </td>
                        <td>
                          {item?.giftId?.coinsAmount
                            ? item?.giftId?.coinsAmount
                            : "0"}
                        </td>
                        <td>
                          {item?.userId?.displayName
                            ? item?.userId?.displayName
                            : "--"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              {giftsent?.length !== 0 && (
                <div className="card-footer clearfix">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Showing {currentPage1 * limit + 1} to{" "}
                      {listLength1 > (currentPage1 + 1) * limit
                        ? (currentPage1 + 1) * limit
                        : listLength1}{" "}
                      of {listLength1} entries
                    </div>
                    <Pagination
                      pageCount={pageCount1}
                      pageValue={currentPage1}
                      setPage={setCurrentPage1}
                    />
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="text-right">
        <button
          type="button"
          className="btn btn-danger py-2 px-2"
          onClick={() => onDeleteUser()}
        >
          Delete Account
        </button>
      </div>
      {loader && <Spinner />}
      <SweetAlert
        show={show}
        warning
        showCancel
        // showCloseButton
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="primary"
        title="Are you sure?"
        onConfirm={handleUpdateImages}
        onCancel={() => setShow(false)}
        focusCancelBtn
      >
        You will not be able to recover this!
      </SweetAlert>
      {showNew && (
        <SweetAlert
          show={showNew}
          warning
          showCancel
          // showCloseButton
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="primary"
          title="Are you sure?"
          onConfirm={() => handleDeleteUser(data?._id)}
          onCancel={() => setShowNew(false)}
          focusCancelBtn
        >
          You will not be able to recover this!
        </SweetAlert>
      )}
      {view && (
        <FullImage
          show={view}
          image={imageForView}
          onHide={() => setView(false)}
        />
      )}
      {sendNoti && (
        <SendNotification
          show={sendNoti}
          id={id}
          onHide={() => setSendNoti(false)}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    currentData: state.UserData.currentData,
  };
};
export default connect(mapStateToProps)(UserDetails);
