import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col, Button } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import {
  blockUser,
  getAllUsers,
  getGroups,
} from "../../services/User/UserService";
import dummy from "../../images/Profile_avatar_placeholder_large.png";
import { notifyError, notifyTopRight } from "../common/Toaster";
import Select from "react-select";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  deleteAdvertise,
  disableEnableAdvertise,
  getAdvertise,
} from "../../services/Advertisement/AdvertiseService";
import AddAdvertise from "../modal/AddAdvertise";
export default function GroupChats(props) {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [listLength, setListlenght] = useState("");
  const [limit, setLimit] = useState(10);
  const imgUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  ///////////////// function to get all users /////////////////////////////////
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getGroups(currentPage, limit);
      setUsers(response.data.data?.groups);
      setLoader(false);
      const total = response.data.data.count;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.data.count);
    } catch (error) {
      setLoader(false);
      if (error?.response?.data?.statusCode === 401) {
        localStorage.clear("pukkatokenDetails");
        history.push("/login");
      }
    }
  };

  ///////////////// function to block/ unlock user /////////////////////////////////
  const onAction = async (id, isDisable) => {
    setLoader(true);
    try {
      const response = await disableEnableAdvertise(id, isDisable);
      {
        isDisable
          ? notifyTopRight("Disabled Successfully!")
          : notifyTopRight("Enabled Successfully!");
      }
      getTableData();
      setLoader(false);
      console.log(response);
    } catch (error) {
      console.log(error.response, "helooooooooo");
      setLoader(false);
      notifyError(error.response?.data?.message);
      if (error.response?.data?.statusCode === 401) {
        localStorage.clear("pukkatokenDetails");
        history.push("/login");
      }
    }
  };
  ///////////////// function to block/ unlock user /////////////////////////////////
  const onDelete = async (id) => {
    setLoader(true);
    try {
      const response = await deleteAdvertise(id);
      notifyTopRight("Deleted Successfully!");
      getTableData();
      setLoader(false);
      console.log(response);
    } catch (error) {
      console.log(error.response, "helooooooooo");
      setLoader(false);
      notifyError(error.response?.data?.message);
      if (error.response?.data?.statusCode === 401) {
        localStorage.clear("pukkatokenDetails");
        history.push("/login");
      }
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage, search, limit]);
  return (
    <div>
      <PageTitle activeMenu="Group Chats" motherMenu="Group Chats" />

      <Card>
        <Card.Body>
          <Table responsive>
            <thead className="text-black">
              <tr>
                <th>
                  <strong>IMAGE</strong>
                </th>
                <th>
                  <strong>group Name</strong>
                </th>
                <th>
                  <strong>Admin</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {users?.map((item, i) => (
                <tr key={i}>
                  <td>
                    <img
                      src={item?.groupImage ? imgUrl + item?.groupImage : dummy}
                      width={70}
                      height={70}
                    />
                  </td>
                  <td>{item?.groupName ? item?.groupName : "--"}</td>
                  <td
                    onClick={() =>
                      props.history.push({
                        pathname: "/user-details",
                        state: { id: item?.admin?._id?._id },
                      })
                    }
                    className="pointer"
                  >
                    {item?.admin?._id?.displayName}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {users?.length === 0 && !loader && (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          )}

          {users?.length !== 0 && (
            <div className="card-footer clearfix">
              <div className="d-block d-md-flex justify-content-between align-items-center">
                <div className="dataTables_info mb-2 mb-md-0">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLength > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLength}{" "}
                  of {listLength} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
              <div className="mt-2 mt-md-0">
                <label className="mr-2">Result Per page : </label>
                <select className="" onChange={(e) => setLimit(e.target.value)}>
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>50</option>
                  <option>100</option>
                </select>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>

      {loader && <Spinner />}
      {showModal && (
        <AddAdvertise
          show={showModal}
          table={getTableData}
          onHide={() => setShowModal(false)}
        />
      )}
    </div>
  );
}
