import React, { useEffect, useState } from "react";
import { notifyError, notifyTopRight } from "../common/Toaster";
import addPhoto, {
  postAdvertise,
} from "../../services/Advertisement/AdvertiseService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Modal } from "react-bootstrap";
import { postEvent } from "../../services/Event/EventService";
import PhoneInput from "react-phone-input-2";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import MetarialTime from "../components/MetarialTime";
export default function AddEvent({ show, table, onHide }) {
  let zipCode = "";
  let city = "";
  const [formData, setFormData] = useState({
    name: "",
    images: [],
    email: "",
    title: "",
    description: "",
    startDate: "",
    endDate: "",
    startTime: null,
    endTime: null,
    city: "",
    venue: "",
    street: "",
    lat: 0,
    lng: 0,
    instaLink: "",
    websiteLink: "",
    countryCode: "",
    phoneNo: "",
  });
  console.log(formData.countryCode, "countrycode in form data");
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  let errorsObj = {
    name: "",
    images: "",
    email: "",
    title: "",
    description: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    city: "",
    venue: "",
    street: "",
    lat: 0,
    lng: 0,
    instaLink: "",
    websiteLink: "",
    countryCode: "",
    phoneNo: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [defaultCountry, setDefaultCountry] = useState("");
  const [image, setImage] = useState(null);
  const albumName = "event_images";
  const [validationMessages, setValidationMessages] = useState({
    websiteLink: "",
    instaLink: "",
  });
  console.log(formData.startTime, "formData.startTime");
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "image") {
      const selectedFiles = e.target.files;
      if (selectedFiles.length > 0) {
        Promise.all(
          Array.from(selectedFiles).map((file) =>
            addPhoto(file, albumName)
              .then((response) => {
                console.log(response, "image response"); // Log the response
                return response.imageName; // Return the image location
              })
              .catch((error) => {
                console.log(error, "image upload error");
                return null; // return null for failed uploads
              })
          )
        ).then((imageLocations) => {
          const filteredLocations = imageLocations.filter(
            (imageName) => imageName !== null
          );
          setFormData({
            ...formData,
            images: [...formData.images, ...filteredLocations],
          });
        });
      }
    } else if (name === "countryCode") {
      setFormData({
        ...formData,
        [name]: "+" + value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const handleInputChangeStartTime = (date) => {
    setFormData((prevState) => ({ ...prevState, startTime: date }));
  };
  const handleInputChangeEndTime = (date) => {
    setFormData((prevState) => ({ ...prevState, endTime: date }));
  };
  const handleAddress = (place) => {
    console.log(place, "places");
    // setLocation({ address: place?.label });
    setFormData({
      ...formData,
      street: place?.label,
    });
    geocodeByAddress(place.label)
      .then((results) => {
        const addressComponents = results[0]?.address_components || [];
        city =
          addressComponents.find(
            (component) =>
              component.types.includes("locality") ||
              component.types.includes("administrative_area_level_2")
          )?.long_name || "";
        // Find the zip code from address components
        zipCode =
          addressComponents.find((component) =>
            component.types.includes("postal_code")
          )?.long_name || "";

        return getLatLng(results[0]);
      })
      .then((res) => {
        setFormData({
          ...formData,
          street: place?.label,
          lat: res.lat,
          lng: res.lng,
        });
      })
      .catch((error) => {
        console.error("Error getting address details:", error);
      });
  };

  const onSubmit = async () => {
    console.log("in on submit function");
    // e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (formData?.images.length === 0) {
      errorObj.image = "This field is Required";
      error = true;
    }
    if (formData.name?.trim() === "") {
      errorObj.name = "This field is Required";
      error = true;
    }
    if (formData.email?.trim() === "") {
      errorObj.email = "This field is Required";
      error = true;
    }
    if (formData.title?.trim() === "") {
      errorObj.title = "This field is Required";
      error = true;
    }
    if (formData.description?.trim() === "") {
      errorObj.description = "This field is Required";
      error = true;
    }
    if (formData.startDate === "") {
      errorObj.startDate = "This field is Required";
      error = true;
    }
    if (formData.endDate === "") {
      errorObj.endDate = "This field is Required";
      error = true;
    }
    if (formData.endDate < formData.startDate) {
      errorObj.endDate = "End date cannot be before start date";
      error = true;
    }
    if (formData.startTime === "") {
      errorObj.startTime = "This field is Required";
      error = true;
    }
    if (formData.endTime === "") {
      errorObj.endTime = "This field is Required";
      error = true;
    }
    const startDateTime = new Date(
      `${formData.startDate}T${formData.startTime}`
    );

    // Combine end date and end time into a single Date object
    const endDateTime = new Date(`${formData.endDate}T${formData.endTime}`);

    // Check if end date is before start date
    if (endDateTime < startDateTime) {
      errorObj.endTime = "End time cannot be before start time";
      error = true;
    }
    if (formData.city?.trim() === "") {
      errorObj.city = "This field is Required";
      error = true;
    }
    if (formData.venue?.trim() === "") {
      errorObj.venue = "This field is Required";
      error = true;
    }
    if (formData.street?.trim() === "") {
      errorObj.street = "This field is Required";
      error = true;
    }
    if (formData.instaLink?.trim() === "") {
      errorObj.instaLink = "This field is Required";
      error = true;
    }
    if (formData.phoneNo?.trim() === "") {
      errorObj.phoneNo = "This field is Required";
      error = true;
    }
    if (
      formData.phoneNo?.trim() !== "" &&
      (formData.phoneNo.length < 6 || formData.phoneNo.length > 15)
    ) {
      errorObj.phoneNo = "Phone number should be between 6 and 15 digits long.";
      error = true;
    }

    if (formData.websiteLink?.trim() === "") {
      errorObj.websiteLink = "This field is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    try {
      const response = await postEvent(formData);
      notifyTopRight("Submit Successfully!");
      table(true);
      onHide();
      setLoader(false);
      console.log(response);
    } catch (error) {
      console.log(error.response, "post api error");
      setLoader(false);
      notifyError(error.response?.data?.message);
      if (error.response?.data?.statusCode === 401) {
        localStorage.clear("pukkatokenDetails");
        history.push("/login");
      }
    }
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zero for single digit months/days
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  };
  const handleLinkValidation = (e, field) => {
    const inputValue = e.target.value;
    const urlPattern =
      /^(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

    if (urlPattern.test(inputValue)) {
      setValidationMessages((prevMessages) => ({
        ...prevMessages,
        [field]: "",
      }));
    } else {
      setValidationMessages((prevMessages) => ({
        ...prevMessages,
        [field]: "Invalid URL",
      }));
    }
  };
  useEffect(() => {
    // Function to fetch user's country based on geolocation
    const fetchUserCountry = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        console.log(data, "country data");
        // if (data && data.country_calling_code) {
        setDefaultCountry(data?.country_code.toLowerCase());
        setFormData({ ...formData, countryCode: data?.country_calling_code });
        // }
      } catch (error) {
        console.error("Error fetching user country:", error);
      }
    };

    fetchUserCountry(); // Call the function to fetch user's country on component mount
  }, []);
  return (
    <Modal className="modal fade" show={show} centered>
      <div className="" role="document">
        <div className="">
          <form>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Add details</h4>
            </div>
            <div className="modal-body overflow-scroll">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Image</label>
                    <div className="contact-name">
                      <input
                        type="file"
                        accept="image/*"
                        multiple
                        className="form-control"
                        name="image"
                        onChange={(e) => handleInputChange(e)}
                      />
                      <span className="validation-text"></span>
                      {errors.image && (
                        <div className="text-danger fs-12">{errors.image}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Name</label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={formData.name}
                        onChange={(e) => handleInputChange(e)}
                      />
                      <span className="validation-text"></span>
                      {errors.name && (
                        <div className="text-danger fs-12">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Email</label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        value={formData.email}
                        onChange={(e) => handleInputChange(e)}
                      />
                      <span className="validation-text"></span>
                      {errors.email && (
                        <div className="text-danger fs-12">{errors.email}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Phone Number</label>
                    <div className="contact-name d-flex justify-content-start">
                      <PhoneInput
                        country={defaultCountry}
                        // country={"us"}
                        enableSearch={true}
                        value={formData.countryCode}
                        name="countryCode"
                        // value="+1"
                        onChange={(phone) =>
                          handleInputChange({
                            target: { name: "countryCode", value: phone },
                          })
                        }
                      />
                      <input
                        type="tel"
                        className="form-control extra-padding"
                        name="phoneNo"
                        value={formData.phoneNo}
                        onChange={(e) => handleInputChange(e)}
                        pattern={"[0-9]*"}
                        onInput={(e) =>
                          (e.target.value = e.target.value.replace(/\D/, ""))
                        }
                      />
                    </div>
                    {errors.phoneNo && (
                      <div className="text-danger fs-12">{errors.phoneNo}</div>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Event Title</label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={formData.title}
                        onChange={(e) => handleInputChange(e)}
                      />
                      <span className="validation-text"></span>
                      {errors.title && (
                        <div className="text-danger fs-12">{errors.title}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">
                      Event Description
                    </label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        name="description"
                        value={formData.description}
                        onChange={(e) => handleInputChange(e)}
                      />
                      <span className="validation-text"></span>
                      {errors.description && (
                        <div className="text-danger fs-12">
                          {errors.description}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">City</label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        value={formData.city}
                        onChange={(e) => handleInputChange(e)}
                      />
                      <span className="validation-text"></span>
                      {errors.city && (
                        <div className="text-danger fs-12">{errors.city}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Venue Name</label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        name="venue"
                        value={formData.venue}
                        onChange={(e) => handleInputChange(e)}
                      />
                      <span className="validation-text"></span>
                      {errors.venue && (
                        <div className="text-danger fs-12">{errors.venue}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Full Address</label>

                    <div className="contact-name">
                      <GooglePlacesAutocomplete
                        className="form-control"
                        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                        // apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                        selectProps={{
                          placeholder: "Enter Address",
                          // name: "address",
                          onChange: (place) => {
                            handleAddress(place);
                            console.log(JSON.stringify(place), "hcfgvjb");
                          },
                        }}
                      />
                      {/* {errors.street && (
                        <div className="text-danger fs-12">{errors.street}</div>
                      )} */}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Start Date</label>
                    <div className="contact-name">
                      <input
                        type="date"
                        className="form-control"
                        name="startDate"
                        value={formData.startDate}
                        onChange={(e) => handleInputChange(e)}
                        min={getCurrentDate()}
                        pattern="\d{4}-\d{2}-\d{2}"
                        // placeholder="dd-mm-yyyy"
                      />
                      <span className="validation-text"></span>
                      {errors.startDate && (
                        <div className="text-danger fs-12">
                          {errors.startDate}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Start Time</label>
                    <div className="contact-name">
                      <div className="input-group clockpicker">
                        <MetarialTime
                          value={formData.startTime}
                          onChange={(e) => handleInputChangeStartTime(e)}
                        />
                        <span className="input-group-append">
                          <span className="input-group-text">
                            <i className="fa fa-clock-o"></i>
                          </span>
                        </span>
                      </div>
                      {/* <input
                        type="time"
                        className="form-control"
                        name="startTime"
                        value={formData.startTime}
                        onChange={(e) => handleInputChange(e)}
                       
                      /> */}
                      <span className="validation-text"></span>
                      {errors.startTime && (
                        <div className="text-danger fs-12">
                          {errors.startTime}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">End Date</label>
                    <div className="contact-name">
                      <input
                        type="date"
                        className="form-control"
                        name="endDate"
                        value={formData.endDate}
                        onChange={(e) => handleInputChange(e)}
                        min={getCurrentDate()}
                      />
                      <span className="validation-text"></span>
                      {errors.endDate && (
                        <div className="text-danger fs-12">
                          {errors.endDate}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">End Time</label>
                    <div className="contact-name">
                      <div className="input-group clockpicker">
                        <MetarialTime
                          value={formData.endTime}
                          onChange={(e) => handleInputChangeEndTime(e)}
                        />
                        <span className="input-group-append">
                          <span className="input-group-text">
                            <i className="fa fa-clock-o"></i>
                          </span>
                        </span>
                      </div>
                      {/* <input
                        type="time"
                        className="form-control"
                        name="endTime"
                        value={formData.endTime}
                        onChange={(e) => handleInputChange(e)}
                        step="1" // Allows the input to accept time in HH:mm:ss format
                      /> */}
                      <span className="validation-text"></span>
                      {errors.endTime && (
                        <div className="text-danger fs-12">
                          {errors.endTime}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Link</label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        name="websiteLink"
                        value={formData.websiteLink}
                        onChange={(e) => handleInputChange(e)}
                        onBlur={(e) => handleLinkValidation(e, "websiteLink")}
                      />

                      <span className="validation-text text-danger fs-12">
                        {validationMessages.websiteLink}
                      </span>
                      {errors.websiteLink && (
                        <div className="text-danger fs-12">
                          {errors.websiteLink}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Instagram</label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        name="instaLink"
                        onChange={(e) => handleInputChange(e)}
                        onBlur={(e) => handleLinkValidation(e, "instaLink")}
                      />
                      <span className="validation-text text-danger fs-12">
                        {validationMessages.instaLink}
                      </span>
                      {errors.instaLink && (
                        <div className="text-danger fs-12">
                          {errors.instaLink}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => onSubmit()}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => onHide()}
                className="btn btn-danger"
              >
                {" "}
                <i className="flaticon-delete-1"></i> Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
