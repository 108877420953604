/// Menu
import MetisMenu from "metismenujs";
import React, { Component, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { sidebarToggleAction } from "../../../store/actions/AuthActions";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}
function SideBar(props) {
  console.log(props, "proppsdsd");
  const [loveEmoji, setLoveEmoji] = useState(false);
  const dispatch = useDispatch();
  let aaa = document.querySelector("#main-wrapper");

  function toggleFunc2() {
    const screenWidth = window.innerWidth;

    // Check if the screen width is less than or equal to 1299px
    if (screenWidth >= 1299) {
      // Do nothing or add any specific behavior for screens <= 1299px
      return;
    }

    // Execute the sidebar toggle logic for screens > 1200px
    dispatch(sidebarToggleAction(false));
    aaa.classList.toggle("menu-toggle");
  }
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  /// Active menu
  let dashBoard = [
      "",
      "analytics",
      "events",
      "order-list",
      "general-customers",
      "reviews",
      "task",
    ],
    users = ["user-management"],
    story = ["story-management"],
    streaming = ["streaming-list"],
    event = ["event-management"],
    advertise = ["advertise-management"],
    reports = ["reports"],
    payouts = ["payouts-management"],
    trans = ["transaction-list"],
    group = ["group-chat-list"],
    notification = ["notification"];

  useEffect(() => {
    let btn = document.querySelector(".nav-control");
    let btn2 = document.querySelector(".backDrop");
    let aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    function toggleFunc2() {
      dispatch(sidebarToggleAction(false));
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
    btn2.addEventListener("click", toggleFunc2);
  }, []);

  return (
    <>
      <div className={`backDrop   ${!props.showSideBar && "d-none"}`}></div>

      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={` ${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" to="/" onClick={toggleFunc2}>
                <i className="flaticon-381-networking"></i>
                <span className="nav-text ">Dashboard</span>
              </Link>
            </li>
            <li className={` ${users.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="user-management"
                className="ai-icon"
                onClick={toggleFunc2}
              >
                <i className="flaticon-381-user-9"></i>
                <span className="nav-text">User Management</span>
              </Link>
            </li>
            <li className={` ${story.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="story-management"
                className="ai-icon"
                onClick={toggleFunc2}
              >
                <i className="flaticon-381-video-clip"></i>
                <span className="nav-text">Moments</span>
              </Link>
            </li>
            <li className={` ${streaming.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="streaming-list"
                className="ai-icon"
                onClick={toggleFunc2}
              >
                <i className="flaticon-381-video-player-1"></i>
                <span className="nav-text">Streamings</span>
              </Link>
            </li>

            <li className={` ${event.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="event-management"
                className="ai-icon"
                onClick={toggleFunc2}
              >
                <i className="flaticon-381-calendar-1"></i>

                <span className="nav-text ">Event Management</span>
              </Link>
            </li>
            <li className={`${advertise.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="advertise-management"
                className="ai-icon"
                onClick={toggleFunc2}
              >
                <i className="flaticon-381-picture"></i>

                <span className="nav-text ">Advertisement</span>
              </Link>
            </li>
            <li className={`${notification.includes(path) ? "mm-active" : ""}`}>
              <Link to="notification" className="ai-icon" onClick={toggleFunc2}>
                <i className="flaticon-381-notification"></i>
                <span className="nav-text">Notification</span>
              </Link>
            </li>
            <li className={`${payouts.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="payouts-management"
                className="ai-icon"
                onClick={toggleFunc2}
              >
                <i className="flaticon-381-price-tag"></i>
                <span className="nav-text">Payouts</span>
              </Link>
            </li>
            <li className={`${trans.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="transaction-list"
                className="ai-icon"
                onClick={toggleFunc2}
              >
                <i className="flaticon-381-list-1"></i>
                <span className="nav-text">Transactions</span>
              </Link>
            </li>
            <li className={`${reports.includes(path) ? "mm-active" : ""}`}>
              <Link to="reports" className="ai-icon" onClick={toggleFunc2}>
                <i className="flaticon-381-flag-1"></i>
                <span className="nav-text">Reports</span>
              </Link>
            </li>
            <li className={`${group.includes(path) ? "mm-active" : ""}`}>
              <Link
                to="group-chat-list"
                className="ai-icon"
                onClick={toggleFunc2}
              >
                <i className="flaticon-381-user-8"></i>
                <span className="nav-text">Group Chats</span>
              </Link>
            </li>
          </MM>
        </PerfectScrollbar>
      </div>
    </>
  );
}

// export default SideBar;

const mapStateToProps = (state) => {
  return {
    showSideBar: state.auth.showSideBar,
  };
};
export default connect(mapStateToProps)(SideBar);
