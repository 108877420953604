export const GET_EVENTS = 'admin/user/events';

export const EVENT_DETAILS = 'admin/user/event';

export const APPROVE_EVENTS = 'admin/user/approveEvent';

export const DELETE_EVENTS = 'admin/user/events';

export const POST_EVENTS = 'admin/user/events';

