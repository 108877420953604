import React, { useEffect, useState } from "react";

/// React router dom
import { Link } from "react-router-dom";

/// images
import logo from "../../../images/balma (1).svg";
import logoText from "../../../images/balma (1).svg";
import { connect, useDispatch } from "react-redux";
import { sidebarToggleAction } from "../../../store/actions/AuthActions";

const NavHader = (props) => {
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  function handleToggleSideBar() {
    dispatch(sidebarToggleAction(!toggle));
    setToggle(!toggle);
  }

  useEffect(() => {
    if (!props.showSideBar) {
      setToggle(false);
    }
  }, [props.showSideBar]);
  return (
    <div className="nav-header" style={{ zIndex: "12" }}>
      <Link to="/" className="brand-logo">
        {toggle ? (
          <img className="logo-abbr" src={logo} alt="" width={60} />
        ) : (
          <img className="brand-title" src={logoText} alt="" />
        )}
        {/* <img className="logo-abbr" src={logo} alt="" />
            <img className="logo-compact ml-5" src={logoText} alt="" />
            <img className="brand-title ml-5" src={logoText} alt="" /> */}
      </Link>

      <div className="nav-control" onClick={handleToggleSideBar}>
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

// export default NavHader;

const mapStateToProps = (state) => {
  return {
    showSideBar: state.auth.showSideBar,
  };
};
export default connect(mapStateToProps)(NavHader);
