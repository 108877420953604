export const GET_USERS = "admin/user/getUsers";

export const GET_ONE_USERS = "admin/user/getUserProfile";

export const GET_GIFTS = "admin/user/gift";

export const DELETE_USERS = "admin/auth/userDelete";

export const BLOCK_USERS = "admin/user/block";

export const DELETE_USER = "admin/user";

export const VERIFY_USERS = "admin/user/verifyProfile";

export const APPROVE_USER_DETAILS = "admin/auth/userApproved";

export const POST_USER_DETAILS = "admin/auth/signupUser";

export const PAYOUTS = "admin/user/payouts";

export const PAYOUTS_ACTION = "admin/user/payouts/action";

export const REMOVE_IMAGES = "admin/user";

export const GET_STORY = "admin/user/stories";

export const DELETE_STORY = "admin/user/story";

export const GET_TRANSACTION = "admin/user/transaction";

export const GET_ALL_STORY = "admin/user/allStories";

export const GET_STREAMINGS = "admin/user/Streaming";

export const GET_GROUPS = "admin/user/groups";

