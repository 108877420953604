import instance from "../Instance";
import { POST_NOTIFICATION, POST_NOTIFICATION_SINGLE, USER_NOTIFICATION } from "./NotificationEndpoints";


export function getUsersAll() {

  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(
    USER_NOTIFICATION ,
    {
      headers: myHeaders,
    }
  );
}
export function postNotificationToOneUser(formData) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  return instance.post(POST_NOTIFICATION_SINGLE, formData, {
    headers: myHeaders,
  });
}
export function postNotification(id, sendToAll, title, description,deviceType) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  let postData;
  if (sendToAll ===true) {
    postData = { sendToAll, title, description ,deviceType};
  } else {
    postData = { sendToAll, usersIds: id, title, description ,deviceType};
  }

  return instance.post(POST_NOTIFICATION, postData, {
    headers: myHeaders,
  });
}
