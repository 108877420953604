import React, { useState } from "react";
/// React router dom
import { Switch, Route } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Dashboard
import Home from "./components/Dashboard/Home";

/// Pages

import Login from "./pages/Login";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";
import UserManagement from "./pages/UserManagement";
import UserDetails from "./pages/UserDetails";
import EventManagement from "./pages/EventManagement";
import Notification from "./pages/Notification";
import EventDetails from "./pages/EventDetails";
import Reports from "./pages/Reports";
import Advertisement from "./pages/Advertisement";
import PayoutsManagement from "./pages/PayoutsManagement";
import TransactionList from "./pages/TransactionList";
import Story from "./pages/Story";
import StrimingList from "./pages/StrimingList";
import GroupChats from "./pages/GroupChats";

const Markup = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },

    /// User management

    { url: "user-management", component: UserManagement },
    { url: "user-details", component: UserDetails },
    { url: "story-management", component: Story },
    
    //////events

    { url: "event-management", component: EventManagement },
    { url: "event-details", component: EventDetails },

    /// pages
    { url: "advertise-management", component: Advertisement },
    { url: "notification", component: Notification },
    { url: "payouts-management", component: PayoutsManagement },
    { url: "reports", component: Reports },
    { url: "page-login", component: Login },
    { url: "transaction-list", component: TransactionList },
    { url: "streaming-list", component: StrimingList },
    { url: "group-chat-list", component: GroupChats },
  ];

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${
            !pagePath ? "content-body" : ""
          }`}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
