import React, { useEffect, useState } from "react";
import { Card, Table, Badge, Nav, Tab } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import moment from "moment";
import dummy from "../../images/dummyImage.png";
import {
  approveEvent,
  deleteEvent,
  getAllEvents,
} from "../../services/Event/EventService";
import { notifyError, notifyTopRight } from "../common/Toaster";
import blueDot from "../../images/Ellipse 254.svg";
import FullImage from "../modal/FullImage";
import AddEvent from "../modal/AddEvent";
export default function EventManagement(props) {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [approvedUsers, setApprovedUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [approvedCurrentPage, setApprovedCurrentPage] = useState(0);
  const [approvedPageCount, setApprovedPageCount] = useState(0);
  const [search, setSearch] = useState("");
  const [listLength, setListlenght] = useState("");
  const [approvedListLength, setApprovedListlenght] = useState("");
  const [limit, setLimit] = useState(10);
  const imgUrl = process.env.REACT_APP_IMAGE_BASE_URL_EVENT;
  const [view, setView] = useState(false);
  const [imageForView, setImageForView] = useState("");
  const [showModal, setShowModal] = useState(false);

  ///////////////// function to get event list for approvel /////////////////////////////////
  const getTableData = async (isPending) => {
    setLoader(true);
    try {
      const response = await getAllEvents(
        currentPage,
        limit,
        search,
        isPending
      );
      console.log(response.data.data.events);
      setUsers(response.data.data.events);
      setLoader(false);
      const total = response.data.data.totalUsers;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.data.totalUsers);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
      if (error?.response?.data?.statusCode === 401) {
        localStorage.clear("pukkatokenDetails");
        props.history.push("/login");
      }
    }
  };
  ///////////////// function to get approved event list  /////////////////////////////////

  const getTableData1 = async (isPending) => {
    console.log(isPending, "status");
    setLoader(true);
    try {
      const response = await getAllEvents(
        currentPage,
        limit,
        search,
        isPending
      );
      setApprovedUsers(response.data.data.events);
      setLoader(false);
      const total = response.data.data.totalUsers;
      setApprovedPageCount(Math.ceil(total / limit));
      setApprovedListlenght(response.data.data.totalUsers);
      setLoader(false);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
      if (error?.response?.data?.statusCode === 401) {
        localStorage.clear("pukkatokenDetails");
        props.history.push("/login");
      }
    }
  };

  ///////////////// function to approve one event  /////////////////////////////////
  const onApprove = async (id, isApproved) => {
    setLoader(true);
    try {
      const response = await approveEvent(id, isApproved);
      notifyTopRight("Done!");
      getTableData(true);
      setLoader(false);
      console.log(response);
    } catch (error) {
      console.log(error, "error");
      notifyError("Something went wrong!");
      setLoader(false);
      if (error?.response?.data?.statusCode === 401) {
        localStorage.clear("pukkatokenDetails");
        props.history.push("/login");
      }
    }
  };
  ///////////////// function to delete event /////////////////////////////////
  const onDelete = async (id) => {
    setLoader(true);
    try {
      const response = await deleteEvent(id);
      notifyTopRight("Deleted Successfully!");
      getTableData(true);
      setLoader(false);
      console.log(response);
    } catch (error) {
      console.log(error.response, "helooooooooo");
      setLoader(false);
      notifyError(error.response?.data?.message);
    }
  };
  useEffect(() => {
    getTableData(true);
    getTableData1(false);
  }, [currentPage, search, limit]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-start">
        <PageTitle activeMenu="Event List" motherMenu="Events" />
        <div>
          <button
            type="button"
            className="btn btn-primary py-2"
            onClick={() => setShowModal(true)}
          >
            Add +
          </button>
        </div>
      </div>

      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey="home">
          <Nav as="ul" className="nav-tabs">
            <Nav.Item as="li">
              <Nav.Link eventKey="home" onClick={() => getTableData(true)}>
                Event Approval Requests
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link eventKey="second" onClick={() => getTableData1(false)}>
                Event List {approvedUsers?.length > 0 && <img src={blueDot} />}
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="pt-4">
            <Tab.Pane eventKey="home">
              <Card>
                <Card.Body>
                  <Table responsive>
                    <thead className="text-black">
                      <tr>
                        <th>
                          <strong>IMAGE</strong>
                        </th>
                        <th>
                          <strong>NAME/Email</strong>
                        </th>

                        <th>
                          <strong>title</strong>
                        </th>

                        <th>
                          <strong>PHONE NUMBER</strong>
                        </th>

                        <th>
                          <strong>start date</strong>
                        </th>
                        <th>
                          <strong>end Date</strong>
                        </th>
                        <th>
                          <strong>Status</strong>
                        </th>
                        <th>
                          <strong>ACTION</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((item, i) => (
                        <tr key={i}>
                          <td
                            // onClick={() =>
                            //   props.history.push({
                            //     pathname: "/event-details",
                            //     state: item,
                            //   })
                            // }
                            onClick={() => {
                              setImageForView(
                                item?.images[0]
                                  ? imgUrl + item?.images[0]
                                  : dummy
                              );
                              setView(true);
                            }}
                            className="pointer"
                          >
                            <img
                              src={
                                item?.images[0]
                                  ? imgUrl + item?.images[0]
                                  : dummy
                              }
                              width={70}
                              height={70}
                            />
                          </td>
                          <td
                            onClick={() =>
                              props.history.push({
                                pathname: "/event-details",
                                state: item,
                              })
                            }
                            className="pointer"
                          >
                            {item?.name}
                            <br />
                            {item?.email}
                          </td>
                          <td
                            onClick={() =>
                              props.history.push({
                                pathname: "/event-details",
                                state: item,
                              })
                            }
                            className="pointer"
                          >
                            {item?.title}
                          </td>
                          <td
                            onClick={() =>
                              props.history.push({
                                pathname: "/event-details",
                                state: item,
                              })
                            }
                            className="pointer"
                          >
                            {item?.countryCode + item?.phoneNo}
                          </td>

                          <td
                            onClick={() =>
                              props.history.push({
                                pathname: "/event-details",
                                state: item,
                              })
                            }
                            className="pointer"
                          >
                            {moment(item?.startDate).format("DD/MM/YYYY")}
                          </td>
                          <td
                            onClick={() =>
                              props.history.push({
                                pathname: "/event-details",
                                state: item,
                              })
                            }
                            className="pointer"
                          >
                            {moment(item?.endDate).format("DD/MM/YYYY")}
                          </td>
                          {/* <td>
                            {item.description.length > 100
                              ? item.description.slice(0, 100) + "..."
                              : item.description}
                          </td> */}
                          <td>
                            {item?.isApproved ? (
                              <Badge variant="success light">Approved</Badge>
                            ) : (
                              <Badge variant="danger  light">Rejected</Badge>
                            )}
                          </td>
                          <td>
                            <div className="d-flex">
                              <button
                                className="btn btn-secondary fs-12 px-2 py-2 mr-2"
                                onClick={() => onApprove(item._id, false)}
                              >
                                Reject
                              </button>
                              <button
                                className="btn btn-primary fs-12 px-2 py-2 mr-2"
                                onClick={() => onApprove(item._id, true)}
                              >
                                Approve
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger light p-2"
                                onClick={() => onDelete(item?._id)}
                              >
                                <i className="flaticon-381-trash-1"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {users?.length === 0 && !loader && (
                    <div className="justify-content-center d-flex my-5 ">
                      Sorry, Data Not Found!
                    </div>
                  )}
                  {users?.length !== 0 && (
                    <div className="card-footer clearfix">
                      <div className="d-block d-md-flex justify-content-between align-items-center">
                        <div className="dataTables_info mb-2 mb-md-0">
                          Showing {currentPage * limit + 1} to{" "}
                          {listLength > (currentPage + 1) * limit
                            ? (currentPage + 1) * limit
                            : listLength}{" "}
                          of {listLength} entries
                        </div>
                        <Pagination
                          pageCount={pageCount}
                          pageValue={currentPage}
                          setPage={setCurrentPage}
                        />
                      </div>
                      <div  className="mt-2 mt-md-0">
                        <label className="mr-2">Result Per page : </label>
                        <select
                          className=""
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option>10</option>
                          <option>20</option>
                          <option>30</option>
                          <option>50</option>
                          <option>100</option>
                        </select>
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Tab.Pane>
          </Tab.Content>
          <Tab.Content>
            <Tab.Pane eventKey="second">
              <Card>
                <Card.Header className="d-block">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col-8">
                      <div>
                        <div className="input-group border bg-white input-group-sm rounded-border">
                          <input
                            type="text"
                            name="table_search"
                            className="form-control float-right border-0 search-input"
                            placeholder="Search"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn btn-default"
                              onClick={getTableData}
                            >
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Table responsive>
                    <thead className="text-black">
                      <tr>
                        <th>
                          <strong>IMAGE</strong>
                        </th>
                        <th>
                          <strong>NAME/email</strong>
                        </th>

                        <th>
                          <strong>title</strong>
                        </th>

                        <th>
                          <strong>PHONE NUMBER</strong>
                        </th>

                        <th>
                          <strong>start date</strong>
                        </th>
                        <th>
                          <strong>end Date</strong>
                        </th>
                        {/* <th>
                          <strong>description</strong>
                        </th> */}
                        <th>
                          <strong>Status</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {approvedUsers?.map((item, i) => (
                        <tr key={i}>
                          <td>
                            <img
                              src={
                                item.images ? imgUrl + item.images[0] : dummy
                              }
                              width={70}
                              height={70}
                              onClick={() => {
                                setImageForView(imgUrl + item.images[0]);
                                setView(true);
                              }}
                            />
                          </td>
                          <td>
                            {item?.name}
                            <br />
                            {item?.email}
                          </td>

                          <td>{item?.title}</td>
                          <td>{item?.countryCode + item?.phoneNo}</td>
                          {/* <td>
                            {item?.venue}, {item?.city}
                          </td> */}
                          <td>
                            {moment(item?.startDate).format("DD/MM/YYYY")}
                          </td>
                          <td>{moment(item?.endDate).format("DD/MM/YYYY")}</td>
                          {/* <td>
                            {item?.description?.length > 200
                              ? item?.description.slice(0, 200) + "..."
                              : item?.description}
                          </td> */}
                          <td>
                            {item?.isApproved ? (
                              <Badge variant="success light">Approved</Badge>
                            ) : (
                              <Badge variant="danger  light">Rejected</Badge>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {approvedUsers?.length === 0 && !loader && (
                    <div className="justify-content-center d-flex my-5 ">
                      Sorry, Data Not Found!
                    </div>
                  )}

                  {approvedUsers?.length !== 0 && (
                    <div className="card-footer clearfix">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="dataTables_info">
                          Showing {approvedCurrentPage * limit + 1} to{" "}
                          {approvedListLength >
                          (approvedCurrentPage + 1) * limit
                            ? (approvedCurrentPage + 1) * limit
                            : approvedListLength}{" "}
                          of {approvedListLength} entries
                        </div>
                        <Pagination
                          pageCount={approvedPageCount}
                          pageValue={approvedCurrentPage}
                          setPage={setApprovedCurrentPage}
                        />
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>

      {loader && <Spinner />}
      {view && (
        <FullImage
          show={view}
          image={imageForView}
          onHide={() => setView(false)}
        />
      )}
      {showModal && (
        <AddEvent
          show={showModal}
          table={getTableData}
          onHide={() => setShowModal(false)}
        />
      )}
    </div>
  );
}
