import React, { useEffect, useState } from "react";
import { notifyError, notifyTopRight } from "../common/Toaster";
import addPhoto, {
  postAdvertise,
} from "../../services/Advertisement/AdvertiseService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Modal } from "react-bootstrap";
import { postNotificationToOneUser } from "../../services/Notification/NotificationService";

export default function SendNotification({ show, id, onHide }) {
  const [formData, setFormData] = useState({
    usersId:id,
    title: "",
    description: "",
  });
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  let errorsObj = { title: "", description: "", ids: "" };
  const [errors, setErrors] = useState(errorsObj);

  const onSubmit = async () => {


    let error = false;
    const errorObj = { ...errorsObj };
    if (formData.title.trim() === "") {
      errorObj.title = "This field is Required";
      error = true;
    }
    if (formData.description.trim() === "") {
      errorObj.description = "This field is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    try {
      const response = await postNotificationToOneUser(formData);
      notifyTopRight("Sent Successfully!");
      onHide();
      setLoader(false);
    } catch (error) {
      console.log(error.response, "post api error");
      setLoader(false);
      notifyError(error.response?.data?.message);
      if (error.response?.data?.statusCode === 401) {
        localStorage.clear("pukkatokenDetails");
        history.push("/login");
      }
    }
  };

  return (
    <Modal className="modal fade" show={show} onHide={onHide} centered>
      <div className="" role="document">
        <div className="">
          <form>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Add details</h4>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Title</label>
                    <div className="contact-name">
                      <input
                        type="text"
                        
                        className="form-control"
                        value={formData.title}
                        onChange={(e) => setFormData({...formData,title:e.target.value})}
                        
                      />
                     
                      {errors.title && (
                        <div className="text-danger fs-12">{errors.title}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Description</label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        value={formData.description}
                        onChange={(e) => setFormData({...formData,description:e.target.value})}
                        
                      />
                     
                      {errors.description && (
                        <div className="text-danger fs-12">{errors.description}</div>
                      )}
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => onSubmit()}
              >
                Send
              </button>
              <button
                type="button"
                onClick={() => onHide()}
                className="btn btn-danger"
              >
                {" "}
                <i className="flaticon-delete-1"></i> Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
