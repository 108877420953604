import React, { useEffect, useState } from "react";
import { Card, Table,} from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import { getStremings } from "../../services/User/UserService";
import dummy from "../../images/Profile_avatar_placeholder_large.png";
import { useHistory } from "react-router-dom";
import FullImage from "../modal/FullImage";
export default function StrimingList(props) {
  // console.log(props, "propsssss");
  const history = useHistory();
  const newpage = props.location.state?.page;
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(newpage ? newpage : 0);
  const [listLength, setListlenght] = useState("");
  const [limit, setLimit] = useState(10);
  const [view, setView] = useState(false);
  const [imageForView, setImageForView] = useState("");
  const imgUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  ///////////////// function to get all users /////////////////////////////////
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getStremings(currentPage, limit);
      console.log(response.data.data);
      setUsers(response.data?.data?.streams);
      setLoader(false);
      const total = response.data.data.count;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.data.count);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
      if (error?.response?.data?.statusCode === 401) {
        localStorage.removeItem("pukkatokenDetails");
        props.history.push("/login");
      }
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage, limit]);

  return (
    <div>
      <PageTitle activeMenu="Streaming List" motherMenu="Streamings" />

      <Card>
        <Card.Body>
          <Table responsive>
            <thead className="text-black">
              <tr>
                <th>
                  <strong>Image</strong>
                </th>
                <th>
                  <strong>User Name</strong>
                </th>
                <th>
                  <strong>description</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((item, i) => (
                <tr key={i}>
                  <td>
                    {" "}
                    <img
                      src={
                        item.publisherId?.images[0]
                          ? imgUrl + item.publisherId?.images[0]
                          : dummy
                      }
                      width={70}
                      height={70}
                      onClick={() => {
                        setImageForView(imgUrl + item.publisherId?.images[0]);
                        setView(true);
                      }}
                    />
                  </td>
                  <td
                    onClick={() =>
                      props.history.push({
                        pathname: "/user-details",
                        state: {
                          id: item?.publisherId?._id,
                          page: currentPage,
                        },
                      })
                    }
                    className="pointer"
                  >
                    {item.publisherId?.displayName
                      ? item.publisherId?.displayName
                      : "--"}
                  </td>

                  <td>{item.description}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {users?.length === 0 && !loader && (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          )}

          {users?.length !== 0 && (
            <div className="card-footer clearfix">
              <div className="d-block d-md-flex justify-content-between align-items-center">
                <div className="dataTables_info mb-2 mb-md-0">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLength > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLength}{" "}
                  of {listLength} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
              <div  className="mt-2 mt-md-0">
                <label className="mr-2">Result Per page : </label>
                <select className="" onChange={(e) => setLimit(e.target.value)}>
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>50</option>
                  <option>100</option>
                </select>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>

      {loader && <Spinner />}
      {view && (
        <FullImage
          show={view}
          image={imageForView}
          onHide={() => setView(false)}
        />
      )}
    </div>
  );
}
