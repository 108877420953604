import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import dummy from "../../images/dummyImage.png";
import moment from "moment/moment";
import Spinner from "../common/Spinner";
import { getEventDetails } from "../../services/Event/EventService";

export default function EventDetails(props) {
  const id = props.location.state;
  const imgUrl = process.env.REACT_APP_IMAGE_BASE_URL_EVENT;
  const [loader, setLoader] = useState(false);
  const [data, setData]= useState();
  const getTableData = async () => {
    
    setLoader(true);
    try {
      const response = await getEventDetails(
        id._id
      );
      setData(response.data.data?.event)
      setLoader(false);
      
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
      if (error?.response?.data?.statusCode === 401) {
        localStorage.clear("pukkatokenDetails");
        props.history.push("/login");
      }
    }
  };
  useEffect(() => {
    getTableData();

  }, []);
  return (
    <div>
      {" "}
      <div className="page-titles px-3">
        <h4>Event Details</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="event-management">Event Management</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>Event Details</Link>
          </li>
        </ol>
      </div>
      <Card>
        <Card.Header>
          <div className="d-block d-sm-flex justify-content-between">
            <div>
              <div className="d-flex  justify-content-start gap-1">
                <img
                  src={data?.images[0] ? imgUrl + data?.images[0] : dummy}
                  width={177}
                  height={177}
                  className="text-center"
                />
                <div className="d-flex flex-column gap-1">
                  <img
                    src={data?.images[1] ? imgUrl + data?.images[1] : dummy}
                    width={80}
                    height={80}
                  />
                  <img
                    src={data?.images[2] ? imgUrl + data?.images[2] : dummy}
                    width={80}
                    height={80}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start pt-3 mb-3 gap-1">
                <img
                  src={data?.images[3] ? imgUrl + data?.images[3] : dummy}
                  width={80}
                  height={80}
                />
                <img
                  src={data?.images[4] ? imgUrl + data?.images[4] : dummy}
                  width={80}
                  height={80}
                />
                <img
                  src={data?.images[5] ? imgUrl + data?.images[5] : dummy}
                  width={80}
                  height={80}
                />
              </div>
            </div>
            <div className="text-left ml-0 ml-sm-5">
              <h3 className="text-color">{data?.title}</h3>
              <div className="d-block d-lg-flex">
                <div>
                  <p>
                    {" "}
                    <strong>Name</strong> - {data?.name ? data?.name : "--"}
                  </p>

                  <p>
                    <strong>Start Date</strong> -{" "}
                    {moment(data?.startDate).format("MM/DD/YYYY")}
                  </p>
                  <p>
                    <strong>Instagram</strong> - {data?.instaLink}
                  </p>
                  <p>
                    <strong>Venue</strong> - {data?.venue}
                  </p>
                </div>
                <div className="ml-0 ml-lg-5">
                  <p>
                    <strong>Email</strong> - {data?.email}
                  </p>

                  <p>
                    <strong>End Date</strong> -{" "}
                    {moment(data?.endDate).format("MM/DD/YYYY")}
                  </p>
                  <p>
                    <strong>Phone Number</strong> - {data?.countryCode}{" "}
                    {data?.phoneNo}
                  </p>
                  <p>
                    <strong>Address</strong> -{" "}
                    {data?.address?.street ? data?.address?.street : "--"}
                  </p>
                </div>
              </div>

              <h5 className="text-black  pt-2 pb-1">Description</h5>
              <p>{data?.description}</p>
            </div>
          </div>
        </Card.Header>
      </Card>
      <Card>
        <Card.Header className="d-block">
          <div className="">
            <h4 className="">Interested Users</h4>
          </div>
        </Card.Header>
        <Card.Body>
          {data?.interestedUsers?.length === 0 && !loader ? (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, No Users!
            </div>
          ) : (
            <Table responsive>
              <thead className="color-black">
                <tr>
                  <th>
                    <strong>NAME</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.interestedUsers?.map((item, i) => (
                  <tr key={i}>
                    <td
                      onClick={() =>
                        props.history.push({
                          pathname: "/user-details",
                          state: { id: item._id },
                        })
                      }
                      className="pointer"
                    >
                      {item.displayName ? item.displayName : "--"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
      <Card>
        <Card.Header className="d-block">
          <div className="">
            <h4 className="">Reports</h4>
          </div>
        </Card.Header>
        <Card.Body>
          {data?.reports?.length === 0 && !loader ? (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, No Reports!
            </div>
          ) : (
            <Table responsive>
              <thead className="color-black">
                <tr>
                  <th>
                    <strong>Title</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Reported By</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.reports?.map((item, i) => (
                  <tr key={i}>
                    <td>{item.title ? item.title : "--"}</td>
                    <td>{item?.description}</td>
                    <td
                      onClick={() =>
                        props.history.push({
                          pathname: "/user-details",
                          state: item?.user?._id,
                        })
                      }
                      className="pointer"
                    >
                      {item?.user?.displayName}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
      {loader && <Spinner />}
    </div>
  );
}
