import React, { useEffect, useState } from "react";
import { notifyError, notifyTopRight } from "../common/Toaster";
import addPhoto, {
  postAdvertise,
} from "../../services/Advertisement/AdvertiseService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Modal } from "react-bootstrap";

export default function AddAdvertise({ show, table, onHide }) {
  const [formData, setFormData] = useState({
    image: "",
    name: "",
    link: "",
  });
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  let errorsObj = { image: "", name: "", link: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [image, setImage] = useState(null);
  const albumName = "balma";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        addPhoto(selectedFile, albumName)
          .then((response) => {
            console.log(response.Location, "image loaction");

            setFormData({
              ...formData,
              image: response.Location,
            });
          })
          .catch((error) => {
            console.log(error, "image upload error");
          });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const onSubmit = async () => {
    console.log("in on submit function");
    // e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (image === "") {
      errorObj.image = "This field is Required";
      error = true;
    }
    if (formData.name.trim() === "") {
      errorObj.name = "This field is Required";
      error = true;
    }
    if (formData.link.trim() === "") {
      errorObj.link = "This field is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    try {
      const response = await postAdvertise(formData);
      notifyTopRight("Submit Successfully!");
      table();
      onHide();
      setLoader(false);
      console.log(response);
    } catch (error) {
      console.log(error.response, "post api error");
      setLoader(false);
      notifyError(error.response?.data?.message);
      if (error.response?.data?.statusCode === 401) {
        localStorage.clear("pukkatokenDetails");
        history.push("/login");
      }
    }
  };

  return (
    <Modal className="modal fade" show={show} onHide={onHide} centered>
      <div className="" role="document">
        <div className="">
          <form>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Add details</h4>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Image</label>
                    <div className="contact-name">
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        name="image"
                        onChange={(e) => handleInputChange(e)}
                        
                      />
                      <span className="validation-text"></span>
                      {errors.image && (
                        <div className="text-danger fs-12">{errors.image}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Name</label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={formData.name}
                        onChange={(e) => handleInputChange(e)}
                        
                      />
                      <span className="validation-text"></span>
                      {errors.name && (
                        <div className="text-danger fs-12">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Link</label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        name="link"
                        value={formData.link}
                        onChange={(e) => handleInputChange(e)}
                        
                      />
                      <span className="validation-text"></span>
                      {errors.link && (
                        <div className="text-danger fs-12">{errors.link}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => onSubmit()}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => onHide()}
                className="btn btn-danger"
              >
                {" "}
                <i className="flaticon-delete-1"></i> Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
