import React, { useEffect, useState } from "react";
import { Card, Table, Badge, Nav, Tab } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import moment from "moment";
import dummy from "../../images/dummyImage.png";
import { approveEvent, getAllEvents } from "../../services/Event/EventService";
import { notifyError, notifyTopRight } from "../common/Toaster";
import blueDot from "../../images/Ellipse 254.svg";
import { approvePayout, getPayoutsList } from "../../services/User/UserService";
export default function PayoutsManagement(props) {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [approvedUsers, setApprovedUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [approvedCurrentPage, setApprovedCurrentPage] = useState(0);
  const [approvedPageCount, setApprovedPageCount] = useState(0);
  const [search, setSearch] = useState("");
  const [listLength, setListlenght] = useState("");
  const [approvedListLength, setApprovedListlenght] = useState("");
  const [limit, setLimit] = useState(10);

  const imgUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  ///////////////// function to get event list for approvel /////////////////////////////////
  const getTableData = async (isNew) => {
    setLoader(true);
    try {
      const response = await getPayoutsList(currentPage, limit, isNew);
      console.log(response.data.data);
      setUsers(response.data.data);
      setLoader(false);
      const total = response.data.data.length;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.data.length);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
      if (error?.response?.data?.statusCode === 401) {
        localStorage.clear("pukkatokenDetails");
        props.history.push("/login");
      }
    }
  };
  ///////////////// function to get approved event list  /////////////////////////////////

  const getTableData1 = async (isNew) => {
    console.log(isNew, "status");
    setLoader(true);
    try {
      const response = await getPayoutsList(currentPage, limit, isNew);
      setApprovedUsers(response.data.data);
      setLoader(false);
      const total = response.data.data.length;
      setApprovedPageCount(Math.ceil(total / limit));
      setApprovedListlenght(response.data.data.length);
      setLoader(false);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
      if (error?.response?.data?.statusCode === 401) {
        localStorage.clear("pukkatokenDetails");
        props.history.push("/login");
      }
    }
  };

  ///////////////// function to approve one event  /////////////////////////////////
  const onApprove = async (id, isApproved) => {
    setLoader(true);
    try {
      const response = await approvePayout(id, isApproved);
      notifyTopRight("Done!");
      getTableData(true);
      setLoader(false);
      console.log(response);
    } catch (error) {
      console.log(error, "error");
      notifyError("Something went wrong!");
      setLoader(false);
      if (error?.response?.data?.statusCode === 401) {
        localStorage.clear("pukkatokenDetails");
        props.history.push("/login");
      }
    }
  };
  useEffect(() => {
    getTableData(true);
    getTableData1(false);
  }, [currentPage, search, limit]);

  return (
    <div>
      <PageTitle activeMenu="Payouts List" motherMenu="Payouts" />
      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey="home">
          <Nav as="ul" className="nav-tabs">
            <Nav.Item as="li">
              <Nav.Link eventKey="home" onClick={() => getTableData(true)}>
                Payout Approval Requests
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link eventKey="second" onClick={() => getTableData1(false)}>
                Payout List {approvedUsers?.length > 0 && <img src={blueDot} />}
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="pt-4">
            <Tab.Pane eventKey="home">
              <Card>
                <Card.Body>
                  <Table responsive>
                    <thead className="text-black">
                      <tr>
                        <th>
                          <strong>Name</strong>
                        </th>
                        <th>
                          <strong>paypal Id</strong>
                        </th>
                        <th>
                          <strong>amount</strong>
                        </th>

                        <th>
                          <strong>admin Action</strong>
                        </th>

                        <th>
                          <strong>ACTION</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {users?.map((item, i) => (
                        <tr key={i}>
                          <td
                            onClick={() =>
                              props.history.push({
                                pathname: "/user-details",
                                state: item?.userId?._id,
                              })
                            }
                            className="pointer"
                          >
                            {item?.userId?.displayName}
                          </td>
                          <td>{item?.paypalId}</td>

                          <td>${item?.amount}</td>

                          <td>
                            {item?.adminAction === "Pending" ? (
                              <Badge variant="danger  light">
                                {item?.adminAction}
                              </Badge>
                            ) : (
                              <Badge variant="success light">
                                {item?.adminAction}
                              </Badge>
                            )}
                          </td>
                          <td>
                            <div className="d-flex">
                              <button
                                className="btn btn-secondary fs-12 px-2 py-2 mr-2"
                                onClick={() => onApprove(item._id, "Reject")}
                              >
                                Reject
                              </button>
                              <button
                                className="btn btn-primary fs-12 px-2 py-2"
                                onClick={() => onApprove(item._id, "Confirm")}
                              >
                                Approve
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {users?.length === 0 && !loader && (
                    <div className="justify-content-center d-flex my-5 ">
                      Sorry, Data Not Found!
                    </div>
                  )}
                  {users?.length !== 0 && (
                    <div className="card-footer clearfix">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="dataTables_info">
                          Showing {currentPage * limit + 1} to{" "}
                          {listLength > (currentPage + 1) * limit
                            ? (currentPage + 1) * limit
                            : listLength}{" "}
                          of {listLength} entries
                        </div>
                        <Pagination
                          pageCount={pageCount}
                          pageValue={currentPage}
                          setPage={setCurrentPage}
                        />
                      </div>
                      <div>
                        <label className="mr-2">Result Per page : </label>
                        <select
                          className=""
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option>10</option>
                          <option>20</option>
                          <option>30</option>
                          <option>50</option>
                          <option>100</option>
                        </select>
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Tab.Pane>
          </Tab.Content>
          <Tab.Content>
            <Tab.Pane eventKey="second">
              <Card>
                {/* <Card.Header className="d-block">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col-8">
                      <div>
                        <div className="input-group border bg-white input-group-sm rounded-border">
                          <input
                            type="text"
                            name="table_search"
                            className="form-control float-right border-0 search-input"
                            placeholder="Search"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn btn-default"
                              onClick={getTableData}
                            >
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Header> */}
                <Card.Body>
                  <Table responsive>
                    <thead className="text-black">
                      <tr>
                        <th>
                          <strong>Name</strong>
                        </th>
                        <th>
                          <strong>paypal Id</strong>
                        </th>
                        <th>
                          <strong>amount</strong>
                        </th>

                        <th>
                          <strong>admin Action</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {approvedUsers?.map((item, i) => (
                        <tr key={i}>
                          <td
                            onClick={() =>
                              props.history.push({
                                pathname: "/user-details",
                                state: item?.userId?._id,
                              })
                            }
                            className="pointer"
                          >
                            {item?.userId?.displayName}
                          </td>
                          <td>{item?.paypalId}</td>

                          <td>${item?.amount}</td>
                          <td>
                            {item?.adminAction === "Pending" ? (
                              <Badge variant="danger  light">
                                {item?.adminAction}
                              </Badge>
                            ) : (
                              <Badge variant="success light">
                                {item?.adminAction}
                              </Badge>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {approvedUsers?.length === 0 && !loader && (
                    <div className="justify-content-center d-flex my-5 ">
                      Sorry, Data Not Found!
                    </div>
                  )}

                  {approvedUsers?.length !== 0 && (
                    <div className="card-footer clearfix">
                      <div className="d-block d-md-flex justify-content-between align-items-center">
                        <div className="dataTables_info mb-2 mb-md-0">
                          Showing {approvedCurrentPage * limit + 1} to{" "}
                          {approvedListLength >
                          (approvedCurrentPage + 1) * limit
                            ? (approvedCurrentPage + 1) * limit
                            : approvedListLength}{" "}
                          of {approvedListLength} entries
                        </div>
                        <Pagination
                          pageCount={approvedPageCount}
                          pageValue={approvedCurrentPage}
                          setPage={setApprovedCurrentPage}
                        />
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>

      {loader && <Spinner />}
    </div>
  );
}
