import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, Card, Table, Badge, Col, Button } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import {
  blockUser,
  getAllUsers,
  verifyUser,
} from "../../services/User/UserService";
import dummy from "../../images/Profile_avatar_placeholder_large.png";
import { notifyError, notifyTopRight } from "../common/Toaster";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useHistory } from "react-router-dom";
import FullImage from "../modal/FullImage";
import { CSVLink } from "react-csv";
export default function UserManagement(props) {
  console.log(props, "propsssss");
  const history = useHistory();
  const newpage = props.location.state?.page;
  const prevType = props.location.state?.userType;
  const prevLimit = props.location.state?.limit;
  const prevCountry = props.location.state?.country;
  const prevSearch = props.location.state?.search;
  const prevDevice = props.location.state?.deviceType;
  const [loader, setLoader] = useState(false);
  const [userSelfie, setUserSelfie] = useState("");
  const [users, setUsers] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(newpage ? newpage : 0);
  const [search, setSearch] = useState(prevSearch ? prevSearch : "");
  const [listLength, setListlenght] = useState("");
  const [limit, setLimit] = useState(prevLimit ? prevLimit : 10);
  const [userType, setUserType] = useState(prevType ? prevType : "");
  const [deviceType, setDeviceType] = useState(prevDevice ? prevDevice : "");
  const imgUrl = process.env.REACT_APP_IMAGE_BASE_URL;
  const [view, setView] = useState(false);
  const [exportData, setExportData] = useState(true);
  const [imageForView, setImageForView] = useState("");
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const [country, setCountry] = useState(prevCountry ? prevCountry : "");
  const options = useMemo(() => countryList().getData(), []);
  const [countryName, setCountryName] = useState("");
  const selfieBaseUrl =
    "https://balmam.s3.ap-south-1.amazonaws.com/verification_photo/";
  //////select country/////
  const changeHandler = (value) => {
    setCountry(value);
    setCountryName(value.value);
  };
  const headers = [
    { label: "NAME", key: "displayName" },
    { label: "EMAIL", key: "email" },
    { label: "PHONE NUMBER", key: "countryCode", key: "phoneNumber" },
    { label: "TOTAL COINS", key: "totalCoins" },
    { label: "TOTAL GEMS", key: "totalGems" },
    { label: "LOGIN TYPE", key: "loginType" },
    { label: "DEVICE TYPE", key: "deviceType" },
    { label: "VERIFIED PROFILE", key: "verifiedUser" },
    { label: "STATUS", key: "isBlocked" },
  ];
  ///////////////// function to get all users /////////////////////////////////
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getAllUsers(
        currentPage,
        limit,
        search,
        countryName,
        userType,
        false,
        deviceType
      );
      console.log(response.data.data.users);
      setUsers(response.data.data.users);
      setNewUsers(response.data.data.users);
      setUserSelfie(response.data.data.users[0]?.selfie);
      setLoader(false);
      const total = response.data.data.totalUsers;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.data.totalUsers);
      if (response.data.data.users < limit) {
        setCurrentPage(0);
      } else {
        setCurrentPage(currentPage);
      }
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
      if (error?.response?.data?.statusCode === 401) {
        localStorage.removeItem("pukkatokenDetails");
        props.history.push("/login");
      }
    }
  };

  ///////////////// function to block/ unlock user /////////////////////////////////
  const onAction = async (id, isBlocked) => {
    setLoader(true);
    try {
      const response = await blockUser(id, isBlocked);
      {
        isBlocked
          ? notifyTopRight("Blocked Successfully!")
          : notifyTopRight("Unblocked Successfully!");
      }
      getTableData();
      setLoader(false);
      console.log(response);
    } catch (error) {
      console.log(error.response, "helooooooooo");
      setLoader(false);
      notifyError(error.response?.data?.message);
      if (error.response?.data?.statusCode === 401) {
        localStorage.removeItem("pukkatokenDetails");
        props.history.push("/login");
      }
    }
  };

  ///////////////// function to verify user /////////////////////////////////
  const onVerify = async (id, verifiedUser) => {
    setLoader(true);
    try {
      const response = await verifyUser(id, verifiedUser);

      {
        verifiedUser
          ? notifyTopRight("Verified Successfully.")
          : notifyTopRight("Rejected Successfully.");
      }

      getTableData();
      setLoader(false);
      console.log(response);
    } catch (error) {
      console.log(error, "helooooooooo");
      setLoader(false);
      notifyError(error.response?.data?.message);
      if (error.response?.data?.statusCode === 401) {
        localStorage.removeItem("pukkatokenDetails");
        props.history.push("/login");
      }
    }
  };
  function onReset() {
    setSearch("");
    setUserType("");
    setDeviceType("");
    setCountry("");
    setCountryName("");
    getTableData();
  }
  function onSearchByButton() {
    if (search) {
      setCurrentPage(0);
      getTableData();
    } else if (countryName || userType) {
      getTableData();
    }
  }
  useEffect(() => {
    getTableData();
  }, [currentPage, limit, exportData]);

  useEffect(() => {
    if (currentPage) {
      history.push({
        pathname: "/user-management",
        state: {
          page: currentPage,
        },
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (limit || search || userType || countryName) {
      history.push({
        pathname: "/user-management",
        state: {
          page: currentPage,
          limit: limit,
          search: search,
          userType: userType,
          country: country,
          deviceType: deviceType,
        },
      });
    }
  }, [limit, search, userType, countryName, deviceType]);

  ///////////////// function to get all users /////////////////////////////////
  const getTableData2 = async () => {
    try {
      const response = await getAllUsers(
        currentPage,
        limit,
        search,
        countryName,
        userType,
        exportData,
        deviceType
      );
      // console.log(response.data.data.users,"");

      setNewUsers(response.data.data.users);
    } catch (error) {
      console.log(error, "error");

      if (error?.response?.data?.statusCode === 401) {
        localStorage.removeItem("pukkatokenDetails");
        props.history.push("/login");
      }
    }
  };

  useEffect(() => {
    getTableData2();
  }, [exportData]);
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <PageTitle activeMenu="Users List" motherMenu="Users" />
        <div className="d-block d-sm-flex justify-content-end align-items-center">
          <CSVLink
            filename={"Users-List.csv"}
            data={newUsers}
            headers={headers}
          >
            <button className="btn btn-primary">
              Export <i class="flaticon-381-send"></i>
            </button>
          </CSVLink>
        </div>
      </div>
      <Card>
        <Card.Header className="d-block d-md-flex" style={{ gap: "1rem" }}>
          <div className="mb-2 mb-md-0 w-100">
            <label className="text-black font-w600 fs-14">Search</label>

            <div>
              <div className="input-group border bg-white input-group-sm rounded-border">
                <input
                  type="text"
                  name="table_search"
                  className="form-control float-right border-0 search-input"
                  placeholder="Search"
                  value={search}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      getTableData();
                      setCurrentPage(0);
                    }
                  }}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={getTableData}
                  >
                    <i className="fa fa-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-2 mb-md-0 w-100">
            <label className="text-black font-w600 fs-14">User Type</label>
            <select
              className="form-control"
              // defaultValue={"all"}
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
            >
              <option value={""} disabled hidden>
                Filter by user type
              </option>
              <option value={"all"}>All</option>
              <option value={"requestForVerification"}>
                Request for Verification
              </option>
              <option value={"isVerified"}>Verified</option>
              <option value={"premium"}>Premium</option>
              <option value={"online"}>Online</option>
            </select>
          </div>

          <div className="mb-2 mb-md-0 w-100">
            <label className="text-black font-w600 fs-14">Device Type</label>

            <select
              className="form-control"
              value={deviceType}
              onChange={(e) => setDeviceType(e.target.value)}
            >
              <option value={""} hidden disabled>
                Filter by Device Type
              </option>
              <option value={"ALL"}>All</option>
              <option value={"IOS"}>IOS</option>
              <option value={"ANDROID"}>ANDROID</option>
              <option value={"WEB"}>WEB</option>
            </select>
          </div>
          <div className="mb-2 mb-md-0 w-100">
            <label className="text-black font-w600 fs-14">Country</label>

            <Select
              placeholder="Country"
              options={options}
              value={country}
              onChange={changeHandler}
            />
          </div>
          <div className="mb-2 mb-md-0 d-flex flex-column">
            <button
              type="button"
              className="btn btn-primary  py-2 mb-2 fs-12"
              onClick={onSearchByButton}
            >
              Search
            </button>
            <button
              type="button"
              className="btn btn-secondary  py-2 fs-12"
              onClick={() => onReset()}
            >
              Reset
            </button>
          </div>
        </Card.Header>
        <Card.Body>
          <Table responsive>
            <thead className="text-black">
              <tr>
                <th>
                  <strong>IMAGE</strong>
                </th>
                {userSelfie && userType === "requestForVerification" && (
                  <th>
                    <strong>Selfie</strong>
                  </th>
                )}

                <th>
                  <strong>NAME</strong>
                </th>

                <th>
                  <strong>PHONE NUMBER</strong>
                </th>
                <th>
                  <strong>total Coins</strong>
                </th>
                <th>
                  <strong>total Gems</strong>
                </th>
                <th>
                  <strong>Login Type</strong>
                </th>
                <th>
                  <strong>device Type</strong>
                </th>

                <th>
                  <strong>Verified Profile</strong>
                </th>
                <th>
                  <strong>STATUS</strong>
                </th>
                <th>
                  <strong>ACTION</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((item, i) => (
                <tr key={i}>
                  <td className="pointer">
                    <img
                      src={item.images[0] ? imgUrl + item.images[0] : dummy}
                      width={70}
                      height={70}
                      onClick={() => {
                        setImageForView(imgUrl + item.images[0]);
                        setView(true);
                      }}
                    />
                  </td>
                  {item?.selfie && userType === "requestForVerification" && (
                    <td>
                      {" "}
                      <img
                        src={
                          item?.selfie ? selfieBaseUrl + item?.selfie : dummy
                        }
                        width={70}
                        height={70}
                        onClick={() => {
                          if (item?.selfie) {
                            setImageForView(selfieBaseUrl + item?.selfie);
                            setView(true);
                          }
                        }}
                      />
                    </td>
                  )}
                  <td
                    onClick={() =>
                      props.history.push({
                        pathname: "/user-details",
                        state: { id: item._id, page: currentPage },
                      })
                    }
                    className="pointer"
                  >
                    {item.displayName ? item.displayName : "--"}
                  </td>
                  <td
                    onClick={() =>
                      props.history.push({
                        pathname: "/user-details",
                        state: { id: item._id, page: currentPage },
                      })
                    }
                    className="pointer"
                  >
                    {" "}
                    {item.phoneNumber
                      ? item.countryCode + " " + item.phoneNumber
                      : "--"}
                  </td>
                  <td
                    onClick={() =>
                      props.history.push({
                        pathname: "/user-details",
                        state: { id: item._id, page: currentPage },
                      })
                    }
                    className="pointer"
                  >
                    {item.totalCoins}
                  </td>
                  <td
                    onClick={() =>
                      props.history.push({
                        pathname: "/user-details",
                        state: { id: item._id, page: currentPage },
                      })
                    }
                    className="pointer"
                  >
                    {item.totalGems}
                  </td>
                  <td
                    onClick={() =>
                      props.history.push({
                        pathname: "/user-details",
                        state: { id: item._id, page: currentPage },
                      })
                    }
                    className="pointer"
                  >
                    {item.loginType}
                  </td>
                  <td
                    onClick={() =>
                      props.history.push({
                        pathname: "/user-details",
                        state: { id: item._id, page: currentPage },
                      })
                    }
                    className="pointer"
                  >
                    {item.deviceType}
                  </td>
                  <td>
                    {item.verifiedUser ? (
                      <Badge variant="success light">Verified</Badge>
                    ) : (
                      <Badge variant="danger light">Not Verified</Badge>
                    )}
                  </td>
                  <td>
                    {item.isBlocked ? (
                      <Badge variant="danger light">Deactive</Badge>
                    ) : (
                      <Badge variant="success light">Active</Badge>
                    )}
                  </td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="info light"
                        className="light sharp btn btn-info i-false"
                      >
                        {svg1}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {userType === "requestForVerification" && (
                          <>
                            <Dropdown.Item
                              onClick={() => onVerify(item._id, true)}
                            >
                              Verify
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => onVerify(item._id, false)}
                            >
                              Reject
                            </Dropdown.Item>
                          </>
                        )}

                        {item.isBlocked ? (
                          <Dropdown.Item
                            onClick={() => onAction(item._id, false)}
                          >
                            Enable
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            onClick={() => onAction(item._id, true)}
                          >
                            Disable
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {users?.length === 0 && !loader && (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          )}

          {users?.length !== 0 && (
            <div className="card-footer clearfix">
              <div className="d-block d-md-flex justify-content-between align-items-center">
                <div className="dataTables_info mb-2 mb-md-0">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLength > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLength}{" "}
                  of {listLength} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
              <div className="mt-2 mt-md-0">
                <label className="mr-2">Result Per page : </label>
                <select
                  className=""
                  value={limit}
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>50</option>
                  <option>100</option>
                </select>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>

      {loader && <Spinner />}
      {view && (
        <FullImage
          show={view}
          image={imageForView}
          onHide={() => setView(false)}
        />
      )}
    </div>
  );
}
