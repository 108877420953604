import instance from "../Instance";
import { DELETE_REPORTS, USER_REPORTS } from "./ReportsEndpoints";

/////get users reports
export function getUserReports(currentPage,limit) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(USER_REPORTS +`?page=${currentPage}&limit=${limit}`, {
    headers: myHeaders,
  });
}
export function deleteReport(id) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.delete(DELETE_REPORTS +`?reportId=${id}`, {
    headers: myHeaders,
  });
}

