import instance from "../Instance";
import { APPROVE_EVENTS, DELETE_EVENTS, EVENT_DETAILS, GET_EVENTS, POST_EVENTS } from "./EventApiendpoints";

export function getAllEvents(currentPage, limit, search, isPending) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(
    GET_EVENTS +
      `?skip=${currentPage}&limit=${limit}&search=${search}&isPending=${isPending}`,
    {
      headers: myHeaders,
    }
  );
}
export function getEventDetails(eventId) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(
    EVENT_DETAILS +
      `?eventId=${eventId}`,
    {
      headers: myHeaders,
    }
  );
}

export function postEvent(formData) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.post(POST_EVENTS, formData, {
    headers: myHeaders,
  });
}

export function approveEvent(eventId, isApproved) {
  const postData = {
    eventId,
    isApproved,
  };
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.put(APPROVE_EVENTS, postData, {
    headers: myHeaders,
  });
}

export function deleteEvent(id) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.delete(DELETE_EVENTS + `?eventId=${id}`, {
    headers: myHeaders,
  });
}
