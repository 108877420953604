import React, { useState } from "react";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

function BasicTImePicker({ value, onChange }) {
  const [selectedDate, handleDateChange] = useState(new Date());

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker
        // autoOk
        className="form-control"
        defaultValue={new Date()}
        ampm={false}
        label=""
        // name="startTime"
        value={value}
        onChange={onChange}
        emptyLabel="--:--"
        // renderInput={(props) => <TextField {...props} />}
      />
    </MuiPickersUtilsProvider>
  );
}

export default BasicTImePicker;
