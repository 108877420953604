import React from "react";
import { Modal } from "react-bootstrap";

export default function FullImage({ show, image, onHide }) {
  return (
    <Modal className="modal fade image-modal" show={show} onHide={onHide} centered>
      <Modal.Header closeButton className="border-0">
      <div className="image-container" role="document">
        <img src={image} alt="img" className="img-fluid" />
      </div>
      </Modal.Header>
      {/* <Modal.Body>
     
      </Modal.Body> */}
     
    </Modal>
  );
}
