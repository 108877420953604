import instance from "../Instance";
import {
  ACTION_ADVERTISE,
  DELETE_ADVERTISE,
  GET_ADVERTISE,
  POST_ADVERTISE,
} from "./AdvertiseEndpoints";
import AWS from "aws-sdk";
////    get  advertisement list //////////
export function getAdvertise(currentPage, limit) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_ADVERTISE + `?page=${currentPage}&limit=${limit}`, {
    headers: myHeaders,
  });
}

//////// Add advertisement /////
export function postAdvertise(formData) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  //   const postData = {  };
  return instance.post(POST_ADVERTISE, formData, {
    headers: myHeaders,
  });
}

//////// block/ unblock advertisement /////
export function disableEnableAdvertise(id, isDisable) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const postData = { advertisementId: id, isDisable };
  return instance.put(ACTION_ADVERTISE, postData, {
    headers: myHeaders,
  });
}

//////// delete advertisement /////

export function deleteAdvertise(id) {
  const data = localStorage.getItem("pukkatokenDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.delete(DELETE_ADVERTISE + `?advertisementId=${id}`, {
    headers: myHeaders,
  });
}

const NEXT_PUBLIC_COGNITO_POOL_ID = process.env.REACT_APP_POOL_ID;
const NEXT_PUBLIC_COGNITO_POOL_REGION = process.env.REACT_APP_BUCKET_REGION;
const NEXT_PUBLIC_S3_BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;

AWS.config.update({
  region: NEXT_PUBLIC_COGNITO_POOL_REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: NEXT_PUBLIC_COGNITO_POOL_ID,
  }),
});

export default async function addPhoto(image, albumName) {
  // console.log(image, albumName, "file in test files");
  var file = image;
  var fileName = Math.floor(Math.random() * 10000000) + file.name;
  var albumPhotosKey = encodeURIComponent(albumName) + "/";
  var photoKey = albumPhotosKey + fileName;

  var upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: NEXT_PUBLIC_S3_BUCKET_NAME,
      Key: photoKey,
      Body: file,
      ACL: "public-read",
    },
  });

  var promise = await upload.promise();
  promise.imageName = fileName;
  // console.log(promise.imageName, promise,"promise");
  return promise;
}
