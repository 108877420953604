import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { Card, Table } from "react-bootstrap";
import Pagination from "../common/Pagination";
import Spinner from "../common/Spinner";
import { notifyError, notifyTopRight } from "../common/Toaster";
import { deleteReport } from "../../services/Reports/ReportsService";
import { deleteStory, getAllStories } from "../../services/User/UserService";
import FullImage from "../modal/FullImage";
import VideoThumbnail from "react-video-thumbnail";
import playBtn from "../../images/play (9) 1.svg";

export default function Story(props) {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListlenght] = useState("");
  const [limit, setLimit] = useState(10);
  const [view, setView] = useState(false);
  const [imageForView, setImageForView] = useState("");
  const [playVdo, setPlayVdo] = useState("");

  const storyImgUrl =
    "https://balmam.s3.ap-south-1.amazonaws.com/story_images/";
  const storyVideoUrl =
    "https://balmam.s3.ap-south-1.amazonaws.com/story_videos/";

  ///////////////// function to fetch list data /////////////////////////////////
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getAllStories(currentPage, limit);
      console.log(response.data);
      setData(response.data.data?.story);
      setLoader(false);
      const total = response.data.data.count;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.data.count);
      if (response.data.data.story < limit) {
        setCurrentPage(0);
      } else {
        setCurrentPage(currentPage);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  const handleDeleteStory = async (storyId) => {
    setLoader(true);
    try {
      const result = await deleteStory(storyId);
      // console.log(result, "updated api response");
      notifyTopRight(result?.data?.message);
      getTableData();
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      setLoader(false);
    }
  };
  const Story = ({ media, index, thumbnail }) => (
    <div>
      {media.type === "image" ? (
        <img
          src={media.url}
          width={200}
          height={100}
          className=""
          alt={`Story ${index}`}
          onClick={() => {
            setImageForView(media.url);
            setView(true);
          }}
        />
      ) : playVdo === index ? (
        <video width={200} height="100" controls autoPlay={playVdo && true}>
          <source src={media.url} type="video/mp4" />
        </video>
      ) : (
        <div className="position-relative">
          <img width={200} height={100} src={thumbnail} />
          <img
            src={playBtn}
            className="vdo-play-btn"
            onClick={() => setPlayVdo(index)}
          />
        </div>
      )}
    </div>
  );
  useEffect(() => {
    getTableData();
  }, [currentPage, limit]);

  return (
    <div>
      <PageTitle activeMenu="Moments" motherMenu="Moments" />

      <Card>
        <Card.Body>
          <Table responsive>
            <thead className="text-black">
              <tr>
                <th>
                  <strong>Moment</strong>
                </th>
                <th>
                  <strong>User Name</strong>
                </th>
                <th>
                  <strong>Phone Number</strong>
                </th>
                <th className="text-center">
                  <strong>Action</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, i) => (
                <tr key={i}>
                  <td>
                    {" "}
                    <Story
                      media={
                        item?.videoUrl
                          ? {
                              type: "video",
                              url: storyVideoUrl + item?.videoUrl,
                            }
                          : { type: "image", url: storyImgUrl + item?.image }
                      }
                      index={i}
                      thumbnail={storyImgUrl + item?.thumbnail}
                    />
                  </td>
                  <td> {item?._creator?.displayName}</td>

                  <td>
                    {`${item?._creator?.countryCode ?? ""} ${
                      item?._creator?.phoneNumber ?? ""
                    }`}
                  </td>
                  <td className="text-center">
                    <button
                      type="button"
                      className="btn btn-danger light p-2"
                      onClick={() => handleDeleteStory(item?._id)}
                    >
                      <i className="flaticon-381-trash-1"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {data?.length === 0 && !loader && (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          )}
          {data?.length !== 0 && (
            <div className="card-footer clearfix">
              <div className="d-block d-md-flex justify-content-between align-items-center">
                <div className="dataTables_info mb-2 mb-md-0">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLength > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLength}{" "}
                  of {listLength} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
              <div className="mt-2 mt-md-0">
                <label className="mr-2">Result Per page : </label>
                <select className="" onChange={(e) => setLimit(e.target.value)}>
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>50</option>
                  <option>100</option>
                </select>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
      {view && (
        <FullImage
          show={view}
          image={imageForView}
          onHide={() => setView(false)}
        />
      )}
      {loader && <Spinner />}
    </div>
  );
}
